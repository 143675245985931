// TODO: use it everywhere

import { skipRejectEnabled, traceEnabled } from "../utils/isDebug";

type Params = {
    withTrace?: true;
    withSkipReject?: true;
};

export default ({ withTrace, withSkipReject }: Params = {}): boolean => {
    /* update log.js if change this logic */
    const isTest = typeof window !== 'undefined' && !!window.___nodeTests;

    if (withTrace) return traceEnabled() && isTest;

    if (withSkipReject) return skipRejectEnabled() && isTest;

    return isTest;
};
