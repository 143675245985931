/* @flow */

const InstagramUrl = {
    ROOT: '/instagram',
    AUTH_CONNECT: '/auth/connect',
    AUTHORISE_CALLBACK: '/auth/authorize',
    GET_ACCESS_TOKEN: '/auth/getAccessToken',
    GET_USER_MEDIA: '/getUserMedia',
    MARK_USER_FOR_DELETE: '/markUserForDelete',
    DATA_DELETE: '/auth/datadelete'
};

module.exports = {
    InstagramUrl
};
