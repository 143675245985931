/**
 * TODO: tests
 */

const equal = (a, b, order = false) => {
    if (a === b) return true;
    if (a === null || b === null) return false;
    if (a.length !== b.length) return false;

    const
        acopy = [...a],
        bcopy = [...b];

    if (!order) {
        acopy.sort();
        bcopy.sort();
    }

    for (let i = 0; i < acopy.length; i++) {
        if (acopy[i] !== bcopy[i]) return false;
    }

    return true;
};

module.exports = {
    equal,
};
