import DataPageSet from './DataPageSet';
import makeUuid from "../../utils/makeUuid";

export default class BlankDataPageSet extends DataPageSet {
    constructor(pageName: string, templateId: string) {
        const data = {
            page: {
                id: makeUuid(),
                type: 'web.data.components.Page',
                name: pageName,
                templateId,
                shareHeaderAndFirstSectionBgImg: false,
                shareModernHeaderAndFirstSectionBgImg: false,
                shareBgImgOffsetTop: 0,
                items: []
            },
            template: {
                id: templateId
            },
            stylesheet: {}
        };
        super(data);
    }
}
