import {
    PAGE_DATA_COMPONENT,
    SITE_DATA,
    SITE_SETTINGS,
    STYLESHEET_DATA,
    TEMPLATE_DATA_COMPONENT,
} from '../constants';
import type { DalDocType } from '../flowTypes';

export const
    dalDocKey = (type: DalDocType, id: string): string => `${type}:${id}`,
    pageDocKey = (id: string): string => dalDocKey(PAGE_DATA_COMPONENT, id),
    templateDocKey = (id: string): string => dalDocKey(TEMPLATE_DATA_COMPONENT, id),
    stylesheetDocKey = (id: string): string => dalDocKey(STYLESHEET_DATA, id),
    siteDocKey = (): string => dalDocKey(SITE_DATA, 'site'),
    siteSettingsDocKey = (): string => dalDocKey(SITE_SETTINGS, 'settings');
