
const InstagramGalleryKind = 'INSTAGRAM_GALLERY';
const InstagramGallerySquareType = 'InstagramGallerySquareType';

export {
    InstagramGalleryKind as default,
    InstagramGalleryKind,
    InstagramGallerySquareType,
};

