import * as R from "ramda";
import { immerable } from "immer";
import { mapDataSiteItem, isPageRef, cbGetDataPageRef, type DataSiteItem } from "./utils/dataSiteItemUtils";
import type { ImageAsset } from "../../src/components/App/flowTypes";

export type PageRobots = 'noindex' | 'all';
export type DataPageRefUrlPath = Array<string>;

export const
    PAGE_INDEX_URL = 'index',
    PAGE_INDEX_HTML_URL = 'index.html',
    PAGE_ROBOTS_NOINDEX = 'noindex',
    PAGE_ROBOTS_ALL = 'all';

export default class DataPageRef {
    type!: 'web.data.links.LinkPage';
    id!: string;
    pageId!: string;
    templateId!: string;
    name!: string;
    title!: string;
    url!: string;
    categories!: Array<string>;
    items: Array<DataSiteItem>;
    description!: string;
    hidden!: boolean;
    public!: boolean;
    customTitle!: string;
    robots!: PageRobots;
    socialShareAsset: null | undefined | ImageAsset;

    constructor(data: Record<string, any>, skipLinkPages: boolean = false) {
        // https://immerjs.github.io/immer/complex-objects/

        this[immerable] = true;
        // fix data inconsistency (in some cases)
        const defaultData = {
            title: data.title || data.name || '',
            description: '',
            robots: PAGE_ROBOTS_ALL,
            customTitle: '',
            socialShareAsset: null,
        };

        Object.assign(this, defaultData, data);

        const mapItem = item => mapDataSiteItem(item, skipLinkPages);
        this.items = R.pipe(
            R.when(() => skipLinkPages, R.filter(itemData => isPageRef(itemData))),
            R.map(mapItem)
        )(data.items);
    }

    isPublic() {
        return this.public;
    }

    getPageRefs(): Array<DataPageRef> {
        const pages = [];
        this.getItems().forEach(cbGetDataPageRef.bind(null, pages));
        return pages;
    }

    getItems() {
        return this.items;
    }
}
