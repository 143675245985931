/* @flow */

const { inherits } = require('util');

const protectedMap = new WeakMap();

const Ctor = {
    super: (Base/*: Function */, context/*: Object | Array<any> */, protectedMembers/*:? Object */) => {
        const [that, args] = Array.isArray(context) ? [context[0], context.slice(1)] : [context, []];
        Base.apply(that, args);

        if (Base.$protected && !that.constructor.$protected) {
            if (protectedMembers) {
                Ctor.protected(that.constructor, {
                    ...Base.$protected(context),
                    ...protectedMembers,
                });
            } else {
                that.constructor.$protected = Base.$protected;
            }
        }
    },

    extends: inherits,

    protected: (Class/*: Function */, members/*: Object */) => {
        Class.$protected = context => {
            if (!protectedMap.has(context)) {
                protectedMap.set(context, Object.freeze(members));
            }
            return protectedMap.get(context);
        };
    },
};

module.exports = { Ctor };
