const WebshopKind = 'WEBSHOP';
const FeaturedProductsSingleType = 'FeaturedProductsSingleType';
const FeaturedProductsGridType = 'FeaturedProductsGridType';

export {
    WebshopKind as default,
    WebshopKind,
    FeaturedProductsGridType,
    FeaturedProductsSingleType
};

