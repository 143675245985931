const ImageSliderKind = 'IMAGESLIDER';
const ImageSliderBasicType = 'ImageSliderBasicType';
const ImageSliderNavigationType = 'ImageSliderNavigationType';
const ImageSliderPaginationType = 'ImageSliderPaginationType';
const ImageSliderCaptionBoxType = 'ImageSliderCaptionBoxType';
export default ImageSliderKind;

export {
    ImageSliderKind,
    ImageSliderBasicType,
    ImageSliderNavigationType,
    ImageSliderCaptionBoxType,
    ImageSliderPaginationType
};
