/* @flow */

module.exports = {
    label: 'Checkdomain',
    startYear: '2000',
    logoTargetUrl: 'https://www.checkdomain.de',
    cookiePolicyUrl: 'https://www.checkdomain.de/agb/datenschutz/',
    privacyPolicyUrl: 'https://www.checkdomain.de/agb/datenschutz/',
    termsUrl: 'https://www.checkdomain.de/agb/',
    helpContactUrl: 'https://www.checkdomain.de/support/kontakt/',
    sourceEmailPartner: "checkdomain",
};
