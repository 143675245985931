import Cache from '../Cache';
import { CACHE_NS } from './constants';

let cache;

export const getCache = () => {
    return cache;
};

export const initCache = () => {
    cache = new Cache(CACHE_NS);
};
