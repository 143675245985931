/* eslint-disable max-classes-per-file */
import type { FetchMockHeaders } from "./mockFetch";
import { DalErrorName } from '../../../dal/constants';

type ResponseParams = {
    body: AnyValue,
    status?: number,
    headers?: FetchMockHeaders,
};

const ResponseDefaults = {
    status: 200,
    headers: {}
};

export class FetchMockResponse {
    body: AnyValue;

    constructor(params: ResponseParams) {
        Object.assign(this, { ...ResponseDefaults, ...params });
    }
}

type ErrorParams = {
    error?: string,
    message?: AnyValue,
};

export class InternalServerErrorResponse extends FetchMockResponse {
    constructor(params: ErrorParams = {}) {
        super({
            status: 500,
            body: {
                error: DalErrorName.INTERNAL_SERVER_ERROR,
                message: 'HTTP 500 Internal Server Error',
                ...params,
            }
        });
    }
}

export class BadRequestErrorResponse extends FetchMockResponse {
    constructor(params: ErrorParams = {}) {
        super({
            status: 400,
            body: {
                error: DalErrorName.BAD_REQUEST,
                message: 'HTTP 400 Bad Request',
                ...params
            }
        });
    }
}

export class NotFoundErrorResponse extends FetchMockResponse {
    constructor(params: ErrorParams = {}) {
        super({
            status: 404,
            body: {
                error: DalErrorName.NOT_FOUND,
                message: 'HTTP 404 Not Found',
                ...params
            }
        });
    }
}

export class ConflictErrorResponse extends FetchMockResponse {
    constructor(params: ErrorParams = {}) {
        super({
            status: 409,
            body: {
                error: DalErrorName.CONFLICT,
                message: 'HTTP 404 Conflict',
                ...params
            }
        });
    }
}

export class InvalidOrMissingCookieResponse extends FetchMockResponse {
    constructor() {
        super({
            status: 200,
            body: {
                error: 'INVALIDORMISSINGCOOKIE',
            },
        });
    }
}

export class WebspaceUploadLimitReached extends FetchMockResponse {
    constructor(params: ErrorParams = {}) {
        super({
            status: 403,
            body: {
                error: DalErrorName.WebspaceUploadLimitReached,
                message: 'HTTP 403 Not Allowed',
                ...params
            }
        });
    }
}
