const makeTruncateIfLonger = (maxLen) => (str) => {
        if (str.length > maxLen) {
            return str.substr(0, maxLen);
        } else {
            return str;
        }
    },
    KB = 1024;

module.exports = {
    ucFirst: str => str[0].toUpperCase() + str.substring(1).toLowerCase(),

    ellipsis: (str, length, filter = '....') => {
        const strlen = str.length;

        if (strlen > length) {
            const
                part1 = str.substr(0, Math.ceil(strlen / 2)),
                part2 = str.substr(Math.floor(strlen / 2));

            return part1.substr(0, Math.ceil(length / 2)) + filter + part2.substr(-1 * Math.floor(length / 2));
        }

        return str;
    },

    /**
     * http://locutus.io/php/strings/trim
     */
    trim: (inStr, charlist) => {
        let whitespace = [
            ' ',
            '\n',
            '\r',
            '\t',
            '\f',
            '\x0b',
            '\xa0',
            '\u2000',
            '\u2001',
            '\u2002',
            '\u2003',
            '\u2004',
            '\u2005',
            '\u2006',
            '\u2007',
            '\u2008',
            '\u2009',
            '\u200a',
            '\u200b',
            '\u2028',
            '\u2029',
            '\u3000'
        ].join('');

        let l = 0,
            i = 0;

        let str = inStr + '';

        if (charlist) {
            whitespace = (charlist + '').replace(/([\[\]\(\)\.\?\/\*\{\}\+\$\^:])/g, '$1'); // eslint-disable-line no-useless-escape
        }

        l = str.length;
        for (i = 0; i < l; i++) {
            if (whitespace.indexOf(str.charAt(i)) === -1) {
                str = str.substring(i);
                break;
            }
        }

        l = str.length;
        for (i = l - 1; i >= 0; i--) {
            if (whitespace.indexOf(str.charAt(i)) === -1) {
                str = str.substring(0, i + 1);
                break;
            }
        }

        return whitespace.indexOf(str.charAt(0)) === -1 ? str : '';
    },

    camelCaseSplit: (inStr, separator = ' ') => {
        return inStr.split(/(?=[A-Z])/g).join(separator);
    },

    toString: val => {
        return val.toString();
    },

    isString: val => typeof val === 'string',

    escapeQuotes: str => str.replace(/['"]/g, '\\$&'),

    unescapeQuotes: str => str.replace(/\\(['"])/g, '$1'),

    truncateIfLonger64KB: makeTruncateIfLonger(64 * KB),

    truncateIfLonger256KB: makeTruncateIfLonger(256 * KB),

    isBlank: val => val.length === 0 || /^\s+$/.test(val),

    unescapeHTML: htmlStr => htmlStr
        .replace(/&lt;/g, "<")
        .replace(/&gt;/g, ">")
        .replace(/&quot;/g, "\"")
        .replace(/&#39;/g, "\'")
        .replace(/&amp;/g, "&")
};
