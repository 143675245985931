export default class DataLinkPage {
    type!: 'web.data.links.LinkExternal';
    id!: string;
    name!: string;
    hidden!: boolean;
    url!: string;
    linkId!: null;
    pageId!: string;
    target!: '_self' | '_blank';

    constructor(data: Record<string, any>) {
        Object.assign(this, { ...data });
    }
}
