const filter = (object, filter) => {
    const
        proto = Object.getPrototypeOf(object),
        copy = { ...object };
    Object.setPrototypeOf(copy, proto);

    return Object.keys(copy).reduce((accum, key) => {
        const value = object[key];

        if (!filter(value, key)) {
            delete copy[key];
        }

        return copy;
    }, {});
};

const filterOutKeys = (object /*: Object*/, blackListKeys /*: Array<string>*/) /*: Object */ =>
    filter(object, (_, k) => blackListKeys.indexOf(k) === -1);

const mapToArray = (object, mapper) => Object.keys(object).map(key => {
    const value = object[key];
    if (mapper) return mapper(value, key);
    return value;
});

const isObject = object => typeof object === 'object' && !Array.isArray(object) && object !== null;

const length = object => Object.keys(object).length;

module.exports = {
    filter,
    filterOutKeys,
    mapToArray,
    isObject,
    length
};
