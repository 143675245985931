import type { BBox } from "../../src/components/App/flowTypes";
import type { MobileData } from "../../src/components/MobileViewEditor/flowTypes";

export default class DataPage {
    type!: "web.data.components.Page";
    id!: string;
    templateId!: string;
    name!: string;
    width!: number;
    height!: number;
    bbox!: BBox;
    items!: Record<string, any>[];
    orderIndex!: number;
    wrap!: boolean;
    stretch!: boolean;
    mobileDown!: boolean;
    mobileData: MobileData = { relations: [], styles: {}, groups: {}, settings: {} };
    time!: number;
    etag!: string;
    rev!: number;
    shareHeaderAndFirstSectionBgImg!: boolean;
    shareModernHeaderAndFirstSectionBgImg: boolean = false;
    shareBgImgOffsetTop!: number;

    constructor(data: Record<string, any>) {
        Object.assign(this, data);
    }
}
