import u from 'updeep';
import * as R from "ramda";

export function applyMappers(sourceState, ...mappers) {
    return mappers.reduce((result, mapper) => {
        if (!mapper) {
            return result;
        }

        const mappingResult = mapper(sourceState);

        if (mappingResult === undefined) {
            return result;
        }

        return u(mappingResult, result);
    }, {});
}

export function makeMapper(map) {
    return function (sourceState) {
        return Object.keys(map).reduce((result, sourceKey) => {
            const newResult = result;
            const targetKey = map[sourceKey];
            newResult[targetKey] = sourceState[sourceKey];
            return newResult;
        }, {});
    };
}

/* map is { sourcePropName:'targetPropName' } */
export function makePlainMappers(map) {
    const
        mapBack = makeBackMap(map);

    return {
        to: makeMapper(map),
        back: makeMapper(mapBack)
    };
}

export function makeBackMap(map: any) {
    return Object.keys(map).reduce((result, sourceKey) => {
        const newResult = result;
        const targetKey = map[sourceKey];
        newResult[targetKey] = sourceKey;
        return newResult;
    }, {});
}

export function makeNullMapper(propName) {
    return function () {
        return { [propName]: null };
    };
}

export function makeFlagsToEnumMapper({ toPropName, toInputMapper, tupleToOldVal, newFromOldMap }) {
    const
        condCase = (oldVal, newVal) => [
            R.either(
                R.equals(oldVal),
                val => {
                    if (R.is(Array)(val)) {
                        return val.every((elem, i) => {
                            const oldElem = oldVal[i];

                            // WBTGEN-2755
                            // this will match any combination of falsy values to mimic old webeditor data inconsistency workarounds
                            if (!elem && !oldElem) {
                                return true;
                            }

                            return R.equals(elem, oldElem);
                        });
                    }
                    return false;
                }
            ),
            R.always(newVal)
        ],
        toCondCase = key => condCase(newFromOldMap[key], { [toPropName]: key }),
        backCondCase = layoutType => condCase(layoutType, tupleToOldVal(newFromOldMap[layoutType])),
        noMatchErrorCond = errorMsg => [R.T, val => {
            throw new Error(`${errorMsg}: ${val}`);
        }],
        final = ({ inputMapper, caseMapper, noMatchError }) => R.pipe(
            inputMapper,
            R.cond([
                ...R.map(caseMapper, Object.keys(newFromOldMap)),
                noMatchErrorCond(noMatchError)
            ])
        );
    return {
        to: final({
            inputMapper: toInputMapper,
            caseMapper: toCondCase,
            noMatchError: 'invalid combination of tuple'
        }),
        back: final({
            inputMapper: R.prop(toPropName),
            caseMapper: backCondCase,
            noMatchError: `invalid ${toPropName}`
        })
    };
}

export function valueRepeater(value: any, times = 4) {
    return R.repeat(value, times);
}
