/* @flow */

/*::
    export type TimeParseInput = string | number
 */

/**
 * Parses human readable time string into milliseconds.
 *
 * Formats:
 * - [<N>h][<N>m][<N>s][<N>ms]
 * - [hh]:[mm]:[ss][.ms]
 *
 * Examples table:
 * ---------------
 * - 1h           | 01:00:00
 * - 1h5m         | 01:05:00
 * - 1h5m3s       | 01:05:03
 * - 1h5m3s300ms  | 01:05:03.300
 * - 1h3s         | 01:00:03
 * - 1h3s300ms    | 01:00:03.300
 * - 1h300ms      | 01:00:00.300
 * - 5m           |    05:00
 * - 5m3s         |    05:03
 * - 5m3s300ms    |    05:03.300
 * - 5m300ms      |    05:03.300
 * - 3s           |       03
 * - 3s300ms      |       03.300
 * - 300ms        |          .300
 */

const Modifier = {
    MS: 'ms',
    S: 's',
    M: 'm',
    H: 'h',
};

const Multiplier = {
    [Modifier.MS]: n => n,
    [Modifier.S]: n => Multiplier[Modifier.MS](n * 1000),
    [Modifier.M]: n => Multiplier[Modifier.S](n * 60),
    [Modifier.H]: n => Multiplier[Modifier.M](n * 60),
};

const Pattern = {
    LITERAL: /^(\d+h)?(\d+m)?(\d+s)?(\d+ms)?$/,
    TIME: /^(\d{2}):(\d{2}):(\d{2})(?:\.(\d{3}))?$/,
};

const sum = parts => parts.reduce(
    (acc, p) => {
        const [value, modifier] = Array.isArray(p)
            ? p
            // $FlowFixMe
            : p.match(/^(\d+)([a-z]+)$/).slice(1);

        return acc + Multiplier[modifier](parseInt(value, 10));
    },
    0,
);

const timePadding = input => {
    const parts = input.split(':');

    if (parts.length === 1 && input[0] === '.') return `00:00:00${input}`;
    else if (parts.length === 1) return `00:00:${input}`;
    else if (parts.length === 2) return `00:${input}`;
    return input;
};

class InvalidInputTimeParseError extends Error {
    constructor(input/*:? TimeParseInput */) {
        // $FlowFixMe
        const msg = `Invalid input: '${input}'`;

        super(msg);
        this.name = this.constructor.name;
        if (typeof Error.captureStackTrace === 'function') {
            Error.captureStackTrace(this, this.constructor);
        } else {
            this.stack = (new Error(msg)).stack;
        }
    }
}

const timeParse = (input/*:? TimeParseInput */)/*: ?number */ => {
    if (Number.isInteger(input) || input === undefined) {
        // $FlowFixMe
        return input;
    }

    if (typeof input !== 'string') {
        throw new InvalidInputTimeParseError(input);
    }

    let parts;

    if (Pattern.LITERAL.test(input)) {
        parts = input
            .match(Pattern.LITERAL)
            // $FlowFixMe
            .reduce(
                (acc, v, i) => i === 0 || v === undefined ? acc : acc.concat(v),
                [],
            );
    }

    const timeInput = timePadding(input);
    if (Pattern.TIME.test(timeInput)) {
        // $FlowFixMe
        const [, h, m, s, ms] = timeInput.match(Pattern.TIME);

        parts = [
            [h, Modifier.H],
            [m, Modifier.M],
            [s, Modifier.S],
        ];

        if (ms) parts.push([ms, Modifier.MS]);
    }

    if (parts) {
        return sum(parts);
    }

    throw new InvalidInputTimeParseError(input);
};

module.exports = {
    timeParse,
    InvalidInputTimeParseError,
};
