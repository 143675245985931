import pathUtils from "./path";

const
    uriEncode = str =>
        encodeURIComponent(str).replace(/[!'()*]/g, x => `%${x.charCodeAt(0).toString(16).toUpperCase()}`),
    stringifyQuery = function (obj) {
        if (!obj) {
            return '';
        }
        return Object.keys(obj)
            .map(key => {
                const val = obj[key];

                if (val === undefined) {
                    return '';
                }

                if (val === null) {
                    return key;
                }

                if (Array.isArray(val)) {
                    throw new Error(`stringifyQuery: array is not supported. Key: ${key}`);
                }

                return `${key}=${val}`;
            })
            .filter(x => x.length > 0)
            .join('&');
    },
    objUriEncode = (o: Record<string, any>) => Object.keys(o).reduce((acc, key) => ({
        ...acc,
        [key]: o[key] === null ? o[key] : uriEncode(o[key])
    }), {}),
    urlBuild = (path, options: Record<string, any> = {}) => {
        let uri = pathUtils.trim(path);

        if ((options.absolutePath || options.absolutePath === undefined) && uri) uri = '/' + uri;

        if (options.scheme) {
            if (!options.host) throw new Error('Required host is missing to build url');
            const
                scheme = options.scheme.replace(':', '') + ':',
                port = (options.port && `:${options.port}`) || '';
            uri = `${scheme}//${pathUtils.rtrim(options.host)}${port}${uri}`;
        }

        if (path.indexOf(':') !== -1 && !options.skipPathParams) {
            if (!options.pathParams || !Object.keys(options.pathParams).length) {
                throw new Error(`Path ${path} is parametrized but no pathParams supplied`);
            }
            Object.keys(options.pathParams).forEach(param => {
                uri = uri.replace(new RegExp(`:${param}`, 'g'), options.pathParams[param]);
            });
        }

        if (options.query) {
            let query = options.query;
            if (options.encode) query = objUriEncode(query);

            uri += '?' + stringifyQuery(query);
        }

        if (options.hash) {
            let hash = options.hash;
            if (options.encode) hash = objUriEncode(hash);
            uri += '#' + stringifyQuery(hash);
        }

        return uri;
    };

export {
    urlBuild as default,
    objUriEncode,
    stringifyQuery
};
