export default class DataSectionLink {
    type!: 'web.data.links.LinkSection';
    id!: string;
    name!: string;
    hidden!: boolean;
    pageId!: string;
    sectionId!: string;

    constructor(data: Record<string, any>) {
        Object.assign(this, { ...data });
    }
}
