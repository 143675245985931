const GalleryKind = 'GALLERY';
const GallerySquareType = 'GallerySquareType';
const GalleryRoundType = 'GalleryRoundType';
const GalleryCaptionsType = 'GalleryCaptionsType';
const GalleryRoundedType = 'GalleryRoundedType';

export {
    GalleryKind as default,
    GalleryKind,
    GallerySquareType,
    GalleryRoundType,
    GalleryCaptionsType,
    GalleryRoundedType,
};

