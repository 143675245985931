// Do not remove below require statements. Needed to map config files in trial dist bundle.
require('./onecom.config.js'); // eslint-disable-line import/extensions
require('./hostnet.config.js'); // eslint-disable-line import/extensions
require('./checkdomain.config.js'); // eslint-disable-line import/extensions
require('./onecom.production.config.js'); // eslint-disable-line import/extensions
require('./hostnet.production.config.js'); // eslint-disable-line import/extensions
require('./checkdomain.production.config.js'); // eslint-disable-line import/extensions
require('./onecom.staging.config.js'); // eslint-disable-line import/extensions
require('./hostnet.staging.config.js'); // eslint-disable-line import/extensions
require('./checkdomain.staging.config.js'); // eslint-disable-line import/extensions
require('./onecom.next.config.js'); // eslint-disable-line import/extensions
require('./hostnet.next.config.js'); // eslint-disable-line import/extensions
require('./checkdomain.next.config.js'); // eslint-disable-line import/extensions
require('./onecom.test.config.js'); // eslint-disable-line import/extensions
require('./hostnet.test.config.js'); // eslint-disable-line import/extensions
require('./checkdomain.test.config.js'); // eslint-disable-line import/extensions
require('./onecom.development.config.js'); // eslint-disable-line import/extensions
require('./hostnet.development.config.js'); // eslint-disable-line import/extensions
require('./checkdomain.development.config.js'); // eslint-disable-line import/extensions
