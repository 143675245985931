import { Class } from "utility-types";
import * as Types from './flowTypes';
import isSuccessfulResponseSet from './isSuccessfulResponseSet';

export const makeResponseSetMapper = (
    bodyMapper: (body: Types.DalResponseSetGenericBody) => Types.DalResponseSetGenericBody
) =>
    (
        responseSet: Types.DalResponseSet<Types.DalResponseSetGenericBody>
    ): Types.DalResponseSet<Types.DalResponseSetGenericBody> => {
        if (isSuccessfulResponseSet(responseSet)) {
            return { response: responseSet.response, body: bodyMapper(responseSet.body) };
        }
        return responseSet;
    };

export const wrapModelResponse = (ModelClass: Class<any>) => makeResponseSetMapper((body: Record<string, any>) => new ModelClass(body));

export const wrapCollectionModelResponse = (ModelClass: Class<any>) => makeResponseSetMapper(
    (body: Record<string, any>[]): Record<string, any>[] => body.map(data => new ModelClass(data))
);
