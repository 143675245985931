import { backMapper as SvgBackMapper } from './Svg';
import { applyMappers } from "../utils";
import ComponentTypes from '../../../src/view/oneweb/registry/ComponentTypes';

const mapper = {
    [ComponentTypes.SVG_KIND]: SvgBackMapper,
};

const backMapToSaveDataFromComponent = (component: any): any => {
    const typeMapper = mapper[component.type];
    return typeMapper ? applyMappers(component, typeMapper) : component;
};

export const backMapItemsToSaveData = ({ items, ...rest }: any): any => (
    { ...rest, items: items.map(backMapToSaveDataFromComponent) }
);
