/* @flow */

const { isObj } = require('../isType');

/*::
    type Options = {|
        allowMissingProps: boolean,
    |}
 */

const DefaultOptions/*: Options */ = {
    allowMissingProps: false,
};

// TODO: proper return type ?
const FuncProxy = /*:: <T: Object, F: Object> */(
    target/*: T */,
    Func/*: F */,
    options/*: Options */ = DefaultOptions,
)/*: Object */ => new Proxy(target, {
    get(target, prop) {
        if (prop === 'then') {
            return undefined;
        }

        // TODO: handle console.log of Proxy from nodejs properly
        if (typeof prop === 'symbol' || prop === '_isUnexpected' || prop === '_unexpectedType') {
            return target;
        }

        if (prop === '$isProxy') {
            return true;
        }

        if (Func[prop]) {
            if (typeof Func[prop] === 'function') {
                let self;

                if (target.$isProxy) {
                    self = Object.assign(target, Func);
                } else {
                    self = Object.assign({}, target, Func);
                }
                return (...args) => Func[prop].apply(self, args);
            } else if (isObj(Func[prop]) && isObj(target[prop])) {
                return FuncProxy(target[prop], Func[prop]);
            }

            throw new Error(`FuncProxy property '${prop}' is not a function`);
        } else if (target[prop] !== undefined) {
            return target[prop];
        }

        if (options.allowMissingProps) {
            return undefined;
        }

        throw new Error(`Unknown FuncProxy property: ${prop}`);
    },
});

module.exports = { FuncProxy };
