import fetch from '../src/services/fetch';

import { getNetworkLog } from './networkLog';
import { getLocaleData } from './getLocaleData';
import { initCache } from './dalCache';
import { init, createDal, getDAL, isDALInitialized } from "./init";

import $R from '../src/debug/$R';

import {
    PAGE_DATA_COMPONENT,
    TEMPLATE_DATA_COMPONENT,
    ANALYTICS_GOALS_DATA,
    STYLESHEET_DATA,
    SITE_DATA,
    SITE_SETTINGS,
    WEBSPACE_PREFIX,
    REPOSITORY_PREFIX,
    DAL_API_PREFIX,
    SOURCE_REPOSITORY,
    ASSET_IMAGE_DAL_TYPE
} from './constants';

// TODO remove at WBTGEN-13365
$R.decryptDomain = (password, textInput) => {
    fetch(
        `/api/v1/${getDAL().getDomain()}/decryptDomains`,
        {
            method: 'POST', // *GET, POST, PUT, DELETE, etc.
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({ password, textInput }) // body data type must match "Content-Type" header
        }
    ).then(r => r.json().then(b => console.log(b.result)));
};

export {
    init,
    initCache,
    createDal,
    getDAL,
    isDALInitialized,
    getLocaleData,
    getNetworkLog,
    PAGE_DATA_COMPONENT,
    TEMPLATE_DATA_COMPONENT,
    ANALYTICS_GOALS_DATA,
    STYLESHEET_DATA,
    SITE_DATA,
    SITE_SETTINGS,
    WEBSPACE_PREFIX,
    REPOSITORY_PREFIX,
    DAL_API_PREFIX,
    SOURCE_REPOSITORY,
    ASSET_IMAGE_DAL_TYPE,
};
