import 'whatwg-fetch';
import isTestEnv from '../debug/isTestEnv';
import { setHandyTimeout } from '../../../server/shared/utils/timers/setHandyTimeout';
import { FETCH_ABORT_ERROR } from '../../dal/dalErrors';

const DEFAULT_FETCH_TIMEOUT_RETRY = 5;

// https://stackoverflow.com/questions/1007340/javascript-function-aliasing-doesnt-seem-to-work/1162192
let _fetch = (typeof window === 'undefined' ? {} : window).fetch;
let _windowFetch;

if (_fetch) {
    _windowFetch = _fetch.bind(window);
    _fetch = _fetch.bind(window);
}

type Options = Record<string, any>

export function setFetchInstance(instance: any) {
    _fetch = instance;
}

export const getFetchInstance = (): any => {
    if (isTestEnv()) return _fetch;
    throw new Error('Can only get fetch instance under test env');
};

/**
 * Fetch does not expose request.file api of fetch spec ((.
 * That's why we need to read file manually.
 */
const readFile = (url, file) => {
    if (!window.File || !window.FileReader) {
        throw new Error('window.File || window.FileReader is/are undefined');
    }

    return new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.onload = e => {
            resolve(e?.target?.result);
        };
        reader.onerror = e => {
            reject(e);
        };
        reader.readAsArrayBuffer(file);
    });
};

const callFetch = (url, options) => {
    if (options.file) {
        return readFile(url, options.file).then(fileData => {
            const uploadOptions = {
                ...options,
                'Content-Type': options.file.type,
                body: fileData
            };
            // @ts-ignore
            return _fetch(url, uploadOptions);
        });
    }

    if (options.useWindowFetch) {
        return _windowFetch(url, options);
    }

    // @ts-ignore
    return _fetch(url, options);
};

/**
 * A wrapper over whatwg-fetch.
 */
export default (url: string, options: Options = {}) => {
    if (window.AbortController && options.timeout && !isTestEnv()) {
        const
            timeout = options.timeout,
            timeoutRetry = options.timeoutRetry || DEFAULT_FETCH_TIMEOUT_RETRY;

        return new Promise/*::<any>*/((resolve, reject) => {
            let timer, retryCount = 0;

            const doFetch = () => {
                const
                    abortCtrl = new AbortController(),
                    fetchOptions = {
                        ...options,
                        signal: abortCtrl.signal,
                    };

                callFetch(url, fetchOptions)
                    .then(res => {
                        clearTimeout(timer);
                        resolve(res);
                    })
                    .catch(error => {
                        if (error.name === FETCH_ABORT_ERROR) {
                            tryFetch();
                        } else {
                            clearTimeout(timer);
                            reject(error);
                        }
                    });

                return abortCtrl;
            };

            let abortCtrl;

            function tryFetch() {
                abortCtrl = doFetch();

                timer = setHandyTimeout(() => {
                    if (retryCount < timeoutRetry) {
                        retryCount++;
                        abortCtrl.abort();
                    } else {
                        clearTimeout(timer);
                        reject(`Fetch retries failed for request: ${url} ${JSON.stringify(options)}`);
                    }
                }, timeout);
            }

            tryFetch();
        });
    }

    return callFetch(url, options);
};
