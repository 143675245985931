// TODO: use these from shared ?

export const
    LINK_ACTION_TYPE = "web.data.Action",
    CACHE_NS = 'DalCache',
    DAL_API_PREFIX = '/api',
    WEBSPACE_PREFIX = 'webspace:',
    REPOSITORY_PREFIX = 'repository:',
    SOURCE_REPOSITORY = 'repository',
    // TODO: refactor into DAL_TYPEs
    ANALYTICS_GOALS_DATA = 'web.analytics.goals' as const,
    ASSET_IMAGE_DAL_TYPE = 'web.data.assets.Image',
    ASSET_VIDEO_DAL_TYPE = 'web.data.assets.Video',
    PAGE_DATA_COMPONENT = 'web.data.components.Page',
    SITE_DATA = 'web.data.Site' as const,
    SITE_SETTINGS = 'web.data.SiteSettings',
    THIRD_PARTY_CONNECTIONS = 'thirdPartyConnections',
    STYLESHEET_DATA = 'web.data.styles.Stylesheet',
    TEMPLATE_DATA_COMPONENT = 'web.data.components.Template',
    DalErrorName = {
        INTERNAL_SERVER_ERROR: 'InternalServerError',
        BAD_REQUEST: 'BadRequest',
        NOT_FOUND: 'NotFound',
        CONFLICT: 'Conflict',
        WebspaceUploadLimitReached: 'WebspaceUploadLimitReached',
        INSUFFICIENT_STORAGE: 'InsufficientStorage',
    },
    ThirdPartyImageCollection = {
        UNSPLASH: 'unsplash'
    },
    SstockCollection = {
        FREE: 'free',
        FULL: 'full',
    },
    SstockImageType = {
        PHOTO: 'photo',
        ILLUSTRATION: 'illustration'
    },
    UnsplashImageOrientation = {
        VERTICAL: 'portrait',
        HORIZONTAL: 'landscape'
    },
    SstockImageOrientation = {
        VERTICAL: 'vertical',
        HORIZONTAL: 'horizontal'
    },
    SstockErrorReason = {
        MAX_DOWNLOADS_PER_REQUEST: 'max_downloads_per_request',
        QUOTA_EXCEEDED: 'quota_exceeded',
        PARTIAL_DOWNLOAD: 'partial_download',
        INSUFFICIENT_STORAGE: 'insufficient_storage',
    } as const,
    DataSiteItemId = {
        MORE_CHILD_ID: "more-child-id"
    },
    USER_PREFERENCES_DOC_ID = 'webEditorUiState';

export const WEBSHOP_PREVIEW = 'webshoppreview';
