const EscapeCharPattern = /%[\dA-Z]{2}/;

export const
    encodeWebspaceUri = (uri: string): string => encodeURIComponent(uri),
    decodeWebspaceUri = (uri: string): string => {
        try {
            // TODO: fix properly in WBTGEN-14933
            if (typeof uri !== 'string') {
                return uri;
            }

            let ret = uri;

            while (EscapeCharPattern.test(ret)) {
                ret = decodeURIComponent(ret);
            }

            return ret;
        } catch (e: any) {
            console.log('===e', e);
            return window.unescape(uri);
        }
    },
    reencodeWebspaceUri = (uri: string) => encodeWebspaceUri(decodeWebspaceUri(uri));

