export const
    GET_DOMAIN_FROM_AUTH_COOKIE = 'GET_DOMAIN_FROM_AUTH_COOKIE',
    DOMAIN_FROM_AUTH_COOKIE_VALUE = 'DOMAIN_FROM_AUTH_COOKIE_VALUE',
    GET_EMAIL_FROM_AUTH_COOKIE = 'GET_EMAIL_FROM_AUTH_COOKIE',
    EMAIL_FROM_AUTH_COOKIE_VALUE = 'EMAIL_FROM_AUTH_COOKIE_VALUE',
    GET_COOKIE = 'GET_COOKIE',
    UNSET_COOKIE = 'UNSET_COOKIE',
    SET_COOKIE = 'SET_COOKIE',
    COOKIE_RESULT = 'COOKIE_RESULT';

