/* @flow */

// TODO: move to /partners

const PartnerName = {
    ONECOM: 'onecom',
    HOSTNET: 'hostnet',
    CHECKDOMAIN: 'checkdomain',
    HEROLD: 'herold',
    SITETEMPLATES: 'siteTemplates',
    ZONER: 'zoner',
  }

const partnerIsOnecom = (partner/* : string */)/*: boolean */ =>  partner === PartnerName.ONECOM;
const partnerIsHostnet = (partner/*: string */)/*: boolean */ => partner === PartnerName.HOSTNET;
const partnerIsCheckdomain = (partner/*: string */)/*: boolean */ => partner === PartnerName.CHECKDOMAIN;
const partnerIsHerold = (partner/*: string */)/*: boolean */ => partner === PartnerName.HEROLD;
const partnerIsZoner = (partner/*: string */)/*: boolean */ => partner === PartnerName.ZONER;
const partnerIsSiteTemplates = (partner/*: string */)/*: boolean */ => partner === PartnerName.SITETEMPLATES;

module.exports = {
    PartnerName,
    partnerIsOnecom,
    partnerIsHostnet,
    partnerIsCheckdomain,
    partnerIsHerold,
    partnerIsZoner,
    partnerIsSiteTemplates,
}
