// @flow
/* jshint node: true */

/*::
    type Asset = {
        url: string;
    }
    type Item = any | {
        type: 'SVG';
        asset: Asset;
    }
    type Data = {
        items: Item[];
    }
 */

const { EMPTY_SVG, KIND } = require('../constants.js');

module.exports = {
    mapDocumentSvg: async function (data/*: Data*/, getSvgHandler/*: string => Promise<string>*/)/*: Promise<Data>*/ {
        const mappedData = { ...data };

        const promiseList = [];
        for (let i = 0; i < data.items.length; i++) {
            if (data.items[i] && data.items[i].type === KIND.SVG) {
                promiseList.push(
                    getSvgHandler(data.items[i].asset.url)
                        .then(rawSvg => mappedData.items[i] = { ...data.items[i], rawSvg })
                        .catch(() => mappedData.items[i] = { ...data.items[i], rawSvg: EMPTY_SVG })
                );
            }
        }
        return Promise.all(promiseList).then(() => mappedData);
    }
};
