import DataPageLayout from './DataPageLayout';

export default class DataRepositorySite {
    type!: "web.data.Site";
    id!: "site";
    homePageId!: "";
    folder!: {
        items: [
            Record<string, any>,
            {
                name: "Layouts";
                items: Record<string, any>[]
            }
        ]
    };
    fonts!: [];
    name!: "universal";
    dataVersionNumber!: number;
    time!: number;
    etag!: string;
    rev!: number;

    // TODO: We shall move this out since pageLayouts is not actually a part of DataRepositorySite
    pageLayouts!: Array<any>;

    constructor(data: Record<string, any>) {
        Object.assign(this, data);
    }

    getPageLayouts(): Array<DataPageLayout> {
        const ob = this.folder.items[1].items.map(data => new DataPageLayout(data));
        return ob;
    }

    getPublicLayouts(): Array<DataPageLayout> {
        return this.getPageLayouts().filter(l => !l.hidden && l.public);
    }

    getBlankPageLayout(): DataPageLayout {
        const result = this.getPageLayouts().filter(plr => {
            return plr.isBlank();
        });
        if (!result || result.length > 1) throw new Error('Cannot find bank page layout');

        return result[0];
    }
}
