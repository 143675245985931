import { getWindowLocationQuery } from "../../../utils/getWindowLocationQuery";
import { SstockImagesFullPurchaseMock } from "../../../../../server/shared/shutterstock/constants";

export const getSstockImagesPurchaseMockQuery = (): boolean | Record<string, any> => {
    const
        windowQuery = getWindowLocationQuery(),
        isMockMode = windowQuery[SstockImagesFullPurchaseMock.MODE_NAME] === true,
        query = isMockMode && {
            [SstockImagesFullPurchaseMock.MODE_NAME]: true,
            ...(
                windowQuery[SstockImagesFullPurchaseMock.PRICE_FIELD]
                    ? { price: windowQuery[SstockImagesFullPurchaseMock.PRICE_FIELD] }
                    : {}
            ),
        };

    return query;
};
