import queryString from 'querystring';
import { memoMaxOne } from '../../utils/memo';

export const SKIP_REJECT_KEY = 'SKIP_REJECT';

const factory = (param, isParamHasValue?: boolean) => memoMaxOne(() => {
    // - is not allowed as ENV variable, so replace with _
    const envKey = param.toUpperCase().replace('-', '_');
    if (process && process.env && process.env[envKey] === 'true') {
        if (envKey !== SKIP_REJECT_KEY) {
            console.log('+++ enabling: ', param); // eslint-disable-line
        }
        return true;
    }

    if (typeof window === 'undefined' || !window.location) {
        return false;
    }

    const query = window.location.search.substring(1);
    const parsedQuery = queryString.parse(query);
    if (isParamHasValue) {
        return param in parsedQuery ? parsedQuery[param] : undefined;
    }
    return (param in parsedQuery);
});

export const captureAppState = factory('appstate');
export const reactPerfEnabled = factory('react-perf');
export const traceEnabled = factory('trace');
export const traceGoogleAnalyticsEnabled = factory('trace-ga');
export const tracePerf = factory('trace-perf');
export const extendedTraceEnabled = factory('extended-trace');
export const componentsMapTraceEnabled = factory('components-map-trace');
export const throttleMouse = factory('throttle-mouse');
export const deepStateCompare = factory('deep-state-compare');
export const showVirtualHandles = factory('show-virtual-handles');
export const showMousePosition = factory('show-mouse-position');
export const showMousePositionWithRespectToTemplateArea = factory('show-mouse-position-with-respect-to-template-area');
export const hideCursor = factory('hide-cursor');
export const debugCustomPageId = factory('_debugCustomPageId');
export const disableRaf = factory('disable-raf');
export const disableRecoverAfterException = factory('disable-recover-after-exception');
export const debugComponentsBbox = factory('debug-bbox');
export const debugEmptyRects = factory('debug-empty-rects');
export const skipRejectEnabled = factory('skip-reject');
export const testAutoColorFeedback = factory('test-auto-color-feedback');
export const domainHashLimit = factory('domain-hash-limit', true);
export const toNewWsb = factory('to-new-wsb');
