import { getByPath, setToPath, deleteByPath } from "./src/utils/ramdaEx";

export default class Cache {
    ns: string; // namespace
    data!: Record<string, any>;
    timeout!: Record<string, any>;

    constructor(ns: string = 'Default') {
        this.ns = ns;
        this.flush();
    }

    /**
     * @private
     */
    __getKey(key: string) {
        return [this.ns, key];
    }

    /**
     * Retrieve the data from cache
     * @param {string} The key in the cache object where data will be restored.
     * @param {Any} Default value to be returned in case of Cache-Miss or timeout.
     */
    get(key: string, defVal: any = null) {
        const
            inKey = this.__getKey(key),
            timeout = getByPath(inKey, this.timeout);

        if (timeout && Date.now() > timeout) {
            deleteByPath(inKey, this.timeout);
            return defVal;
        }

        return getByPath(inKey, this.data);
    }

    findKeys(predicate: (AnyValue, string) => boolean): Array<string> {
        const data = this.data[this.ns];
        return Object.keys(data).filter(k => predicate(data[k], k));
    }

    /**
     * Set the data in cache, with optional timeout
     * @param {string} The key in the cache object where data will be stored.
     * @param {Any} Data to be stored in cache.
     * @param {Number} Time in secods for timeout of cache value.
     */
    set(key: string, data: any, timeout: number = 0) {
        const inKey = this.__getKey(key);

        if (timeout > 0) {
            this.timeout = setToPath(inKey, Date.now() + (timeout * 1000), this.timeout);
        }

        this.data = setToPath(inKey, data, this.data);
    }

    remove(key: string) {
        const inKey = this.__getKey(key);
        deleteByPath(inKey, this.data);
        deleteByPath(inKey, this.timeout);
    }

    flush() {
        this.data = { [this.ns]: {} };
        this.timeout = { [this.ns]: {} };
    }
}
