import Auth from "@aws-amplify/auth";
import { getTrialAwsConfig } from "../../../trial/services/getTrialConfig";
import type { AwsTrialConfig } from "../../../trial/services/types";
import { Ctor } from "../../../server/shared/utils/ctor/Ctor";
import { getTrialAuth } from "../../../trial/services/trialAuth";
import { BaseTrialDb } from "../../../trial/services/database/BaseTrialDb";
import { getAppConfig } from "../../src/components/App/epics/appConfig/appConfig";
import type { DalDoc } from "../../dal/flowTypes";
import { DemoStorageDataNotFoundError } from "./DemoStorageDataNotFoundError";
import { dalDocKey } from "../../dal/utils/dalDocKey";
import {
    TRIAL_INSTAGRAM_ACCESS_CREDENTIALS_KEY,
    TRIAL_FACEBOOK_FEED_ACCESS_CREDENTIALS_KEY,
    TRIAL_FACEBOOK_CHAT_ACCESS_CREDENTIALS_KEY
} from "../../../server/shared/trial/constants";

type UpdateExpressionType = {
    ConditionExpression: string;
    UpdateExpression: string;
    RemoveExpression?: string;
    ExpressionAttributeNames: Record<string, string>;
    ExpressionAttributeValues: Record<string, any>;
  }

/*::
    import { TrialAuth } from '../../../trial/services/trialAuth';
    import { AwsDynamoDb } from '../../../server/shared/awsServices/dynamoDb/AwsDynamoDb'
    import type { AwsCredentials } from '../../../server/shared/awsServices/types'

    export type WbtgenTrialDBType = {
        originalDocumentStorage: Record<string, any>,
        get(itemQueryKey: string): PromiseRecord<string, any>,
        set(itemQueryKey: string, documentData: DalDoc): PromiseRecord<string, any>,
        update(itemQueryKey: string, documentData: DalDoc): Promise<void>,
        delete(itemQueryKey: string): Promise<void>,
        batchWrite(
            putItems?: Array<DalDoc>,
            updateItems?: Array<DalDoc>,
            deleteItems?: Array<DalDoc>
        ): PromiseRecord<string, any>,
        getCurrentData(): PromiseRecord<string, any>,
        storeFacebookFeedCredentials(
            accountConnectionDetails: ArrayRecord<string, any>
        ): PromiseRecord<string, any>,
        storeFacebookMessengerChatCredentials(
            messengerChatAccountDetails: Object
        ): PromiseRecord<string, any>
    }
*/

const
    appConfig = getAppConfig(),
    identityPoolId = appConfig.oneWeb.trial.awsConfig.cognito.identityPoolId;

function WbtgenTrialDB(this: any, config: AwsTrialConfig) {
    /*=== Constructor ===*/

    Ctor.super(BaseTrialDb, [this, config]);

    const
        self = this,
        trialAuth/*: TrialAuth */ = getTrialAuth(),
        // TODO(WBTGEN-13431): Use trialAuth only once it completely supports user and associated storage access.
        amplifyAuth = Auth;

    /*=== Public ===*/

    WbtgenTrialDB.prototype.originalDocumentStorage = {};

    WbtgenTrialDB.prototype.get = (itemQueryKey: string): Promise<Record<string, any>> => {
        const getParams = {
            'Key': {
                ...getEssentialKeyData(itemQueryKey)
            }
        };

        return makeGetRequest(getParams)
            .then(data => {
                if (data && data.Item) {
                    this.originalDocumentStorage[itemQueryKey] = data.Item;
                    this.originalDocumentStorage[itemQueryKey].etag = String(data.Item.rev);
                    delete this.originalDocumentStorage[itemQueryKey]._id;
                    delete this.originalDocumentStorage[itemQueryKey]._dataKey;
                    return data.Item;
                } else {
                    throw new DemoStorageDataNotFoundError(
                        `Unable to query DynamoDB with params: ` +
                        `${JSON.stringify(getParams)}, response: ${JSON.stringify(data)}`
                    );
                }
            });
    };

    WbtgenTrialDB.prototype.set = (itemQueryKey: string, documentData: DalDoc) => {
        let originalRev;
        const now = new Date();
        let documentToPut = documentData;
        if (documentData.etag) {
            let parsedDate = new Date(documentData.etag);
            originalRev = parsedDate.getFullYear() === now.getFullYear() ? parsedDate.getTime() : now.getTime();
            delete documentToPut.etag;
        } else {
            originalRev = now.getTime();
        }
        const putParams = {
            'Item': {
                ...getEssentialKeyData(itemQueryKey),
                ...documentToPut,
                rev: originalRev
            }
        };

        return makePutRequest(putParams)
            .then(() => {
                const returnDocData = {
                    ...documentData,
                    etag: String(originalRev)
                };
                this.originalDocumentStorage[itemQueryKey] = returnDocData;
                return returnDocData;
            });
    };

    WbtgenTrialDB.prototype.update = (itemQueryKey: string, documentData: DalDoc) => {
        const newRev = new Date().getTime();

        const updateParams = {
            'Key': {
                ...getEssentialKeyData(itemQueryKey),
            },
            ...getUpdateExpression(itemQueryKey, documentData, newRev),
        };

        return getDyDb()
            .then(documentClient => {
                return documentClient.update(updateParams)
                    .promise()
                    .then(() => {
                        const returnDocData = {
                            ...documentData,
                            etag: String(newRev)
                        };
                        this.originalDocumentStorage[itemQueryKey] = returnDocData;
                        return returnDocData;
                    });
            });
    };

    WbtgenTrialDB.prototype.delete = (itemQueryKey: string) => {
        const deleteParams = {
            'Key': {
                ...getEssentialKeyData(itemQueryKey),
            }
        };

        return getDyDb()
            .then(documentClient => {
                return documentClient.delete(deleteParams)
                    .promise();
            });
    };

    WbtgenTrialDB.prototype.batchWrite = (
        putItems: Array<DalDoc> = [],
        updateItems: Array<DalDoc> = [],
        deleteItems: Array<DalDoc> = []
    ) => {
        const promiseArray: Promise<any>[] = [];
        for (let i = 0; i < putItems.length; i++) {
            const putDoc = putItems[i];
            const dalDocKeyForItem = dalDocKey(putDoc.type, putDoc.id);
            promiseArray.push(this.set(dalDocKeyForItem, putDoc));
        }

        for (let i = 0; i < updateItems.length; i++) {
            const updateDoc = updateItems[i];
            const dalDocKeyForItem = dalDocKey(updateDoc.type, updateDoc.id);
            promiseArray.push(this.update(dalDocKeyForItem, updateDoc));
        }

        for (let i = 0; i < deleteItems.length; i++) {
            const deleteDoc = deleteItems[i];
            const dalDocKeyForItem = dalDocKey(deleteDoc.type, deleteDoc.id);
            promiseArray.push(this.delete(dalDocKeyForItem));
        }

        return Promise.all(promiseArray);
    };

    WbtgenTrialDB.prototype.getCurrentData = (): Promise<Record<string, any>> => {
        const queryParams = {
            'KeyConditionExpression': '#id = :username',
            'ExpressionAttributeNames': {
                '#id': '_id',
            },
            'ExpressionAttributeValues': {
                ':username': getUserPrimaryKey(),
            },
        };

        return getAlldata(queryParams)
            .then(data => {
                let reformattedData = {};
                if (data.Items) {
                    for (let i = 0; i < data.Items.length; i++) {
                        const dataItem = data.Items[i];
                        const documentId = [dataItem._id, dataItem._dataKey].join(':');
                        reformattedData[documentId] = dataItem;
                    }
                }
                return reformattedData;
            });
    };

    WbtgenTrialDB.prototype.storeInstagramCredentials = (
        instagramAccessToken: string,
        instagramAccountName: string,
        expiresIn: number
    ) => {
        const itemQueryKey = TRIAL_INSTAGRAM_ACCESS_CREDENTIALS_KEY,
            documentData = {
                instagramAccessToken,
                instagramAccountName,
                expiresIn,
            };

        return this.set(itemQueryKey, documentData);
    };

    WbtgenTrialDB.prototype.storeFacebookFeedCredentials = (
        accountConnectionDetails: Array<Record<string, any>>
    ) => {
        const itemQueryKey = TRIAL_FACEBOOK_FEED_ACCESS_CREDENTIALS_KEY;

        return this.set(itemQueryKey, {
            accountConnectionDetails
        });
    };

    WbtgenTrialDB.prototype.storeFacebookMessengerChatCredentials = (
        messengerChatAccountDetails: Object
    ) => {
        const itemQueryKey = TRIAL_FACEBOOK_CHAT_ACCESS_CREDENTIALS_KEY;

        return this.set(itemQueryKey, messengerChatAccountDetails);
    };

    /*=== Private ===*/

    function getAlldata(queryDbParams: Record<string, any>, previousResponse: Record<string, any> = {}): Promise<Record<string, any>> {
        return getDyDb()
            .then(documentClient => {
                return documentClient.query(queryDbParams)
                    .promise()
                    .then(response => {
                        const combinedResponse = {
                            Items: [].concat((previousResponse.Items || []), response.Items),
                            ScannedCount: (previousResponse.ScannedCount || 0) + (response.ScannedCount || 0),
                            Count: (previousResponse.Count || 0) + (response.Count || 0),
                            LastEvaluatedKey: response.LastEvaluatedKey,
                        };
                        if (response && response.LastEvaluatedKey) {
                            // eslint-disable-next-line no-param-reassign
                            queryDbParams.ExclusiveStartKey = {
                                ...response.LastEvaluatedKey,
                            };
                            return getAlldata(queryDbParams, combinedResponse);
                        }
                        return combinedResponse;
                    });
            });
    }

    function getDyDb(): Promise<Record<string, any>> { // eslint-disable-line no-unused-vars
        return trialAuth
            .getEssentialCredentials()
            // @ts-ignore
            .then(credentials => WbtgenTrialDB.$protected(self).dyDb({ credentials }));
    }

    function getEssentialKeyData(queryKey: string) {
        // @ts-ignore
        const userIdentityId = amplifyAuth.user.storage[`aws.cognito.identity-id.${identityPoolId}`];
        return {
            // @ts-ignore
            _id: `${userIdentityId}_${amplifyAuth.user.username}`,
            _dataKey: `cur:${queryKey}`
        };
    }

    function makeGetRequest(getParams) {
        return getDyDb()
            .then(documentClient => {
                return documentClient.get(getParams)
                    .promise();
            });
    }

    function makePutRequest(putParams) {
        return getDyDb()
            .then(documentClient => {
                return documentClient.put(putParams)
                    .promise();
            });
    }

    function createUpdateExpression(originalDocument: Record<string, any>, modifiedDocument: Record<string, any>) {
        const updateExpression: UpdateExpressionType = {
            ConditionExpression: '',
            UpdateExpression: '',
            RemoveExpression: '',
            ExpressionAttributeNames: {},
            ExpressionAttributeValues: {}
        };

        const originalDocumentKeys = Object.keys(originalDocument);
        const modifiedDocumentKeys = Object.keys(modifiedDocument);
        const keysToRemove = originalDocumentKeys.filter(keyName => !modifiedDocumentKeys.includes(keyName));

        modifiedDocumentKeys.forEach(documentKey => {
            const setValueString = `#${documentKey} = :${documentKey}`;
            if (updateExpression.UpdateExpression) {
                updateExpression.UpdateExpression += ` , ${setValueString}`;
            } else {
                updateExpression.UpdateExpression = `SET ${setValueString}`;
            }
            updateExpression.ExpressionAttributeNames[`#${documentKey}`] = documentKey;
            updateExpression.ExpressionAttributeValues[`:${documentKey}`] = modifiedDocument[documentKey];
        });

        keysToRemove.forEach(removeKey => {
            const propNameExpressionName = `#${removeKey}`;
            if (updateExpression.RemoveExpression) {
                updateExpression.RemoveExpression += ` , ${propNameExpressionName}`;
            } else {
                updateExpression.RemoveExpression += ` REMOVE ${propNameExpressionName}`;
            }
            updateExpression.ExpressionAttributeNames[`#${removeKey}`] = removeKey;
        });

        return updateExpression;
    }

    function getUpdateExpression(itemQueryKey: string, documentData: DalDoc, newRev: number) {
        let originalDocument = self.originalDocumentStorage[itemQueryKey];
        if (originalDocument.rev) {
            delete originalDocument.rev;
        }

        let modifiedDocument = documentData;
        if (modifiedDocument.rev) {
            delete modifiedDocument.rev;
        }
        const oldRev = parseInt(originalDocument.etag, 10);

        const updateExpression = createUpdateExpression(originalDocument, modifiedDocument);

        updateExpression.ConditionExpression = '#revField = :expectedRev';
        updateExpression.ExpressionAttributeNames['#revField'] = 'rev';
        updateExpression.ExpressionAttributeValues[':expectedRev'] = oldRev;

        if (updateExpression.UpdateExpression) {
            updateExpression.UpdateExpression += ' , #revField = :newRev';
        } else {
            updateExpression.UpdateExpression = 'SET #revField = :newRev';
        }
        updateExpression.ExpressionAttributeValues[':newRev'] = newRev;

        if (updateExpression.RemoveExpression) {
            updateExpression.UpdateExpression += ` ${updateExpression.RemoveExpression}`;
            delete updateExpression.RemoveExpression;
        }
        return updateExpression;
    }

    function getUserPrimaryKey() {
        // @ts-ignore
        const userIdentityId = amplifyAuth.user.storage[`aws.cognito.identity-id.${identityPoolId}`];
        // @ts-ignore
        return `${userIdentityId}_${amplifyAuth.user.username}`;
    }
}
Ctor.extends(WbtgenTrialDB, BaseTrialDb);

let singleton/*: WbtgenTrialDBType */;

export const getWbtgenTrialDb = () /*: WbtgenTrialDBType */ => {
    if (!singleton) {
        singleton = new WbtgenTrialDB(getTrialAwsConfig());
    }
    return singleton;
};
