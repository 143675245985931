/* @flow */

const AwsCognitoErrorName = {
    USER_NOT_FOUND_EXCEPTION: 'UserNotFoundException',
    NOT_AUTHORIZED_EXCEPTION: 'NotAuthorizedException',
};

const AwsCognitoMessageAction = {
    RESEND: 'RESEND',
    SUPPRESS: 'SUPPRESS',
};

const AwsCognitoInitiateAuthFlow = {
    USER_SRP_AUTH: 'USER_SRP_AUTH',
    REFRESH_TOKEN_AUTH: 'REFRESH_TOKEN_AUTH',
    REFRESH_TOKEN: 'REFRESH_TOKEN',
    CUSTOM_AUTH: 'CUSTOM_AUTH',
    ADMIN_NO_SRP_AUTH: 'ADMIN_NO_SRP_AUTH',
    USER_PASSWORD_AUTH: 'USER_PASSWORD_AUTH',
    ADMIN_USER_PASSWORD_AUTH: 'ADMIN_USER_PASSWORD_AUTH',
};

const AwsCognitoInitiateAuthFlowParameters = {
    USERNAME: 'USERNAME',
    PASSWORD: 'PASSWORD',
};

const AwsCognitoResponseToAuthChallengeName = {
    USERNAME: 'USERNAME',
    NEW_PASSWORD: 'NEW_PASSWORD',
};

const AwsCognitoClientStorageRef = {
    LAST_USER_AUTH: 'LastUserAuth',
    ACCESS_TOKEN: 'accessToken',
    REFRESH_TOKEN: 'refreshToken',
    ID_TOKEN: 'idToken',
    IDENTITY_SERVICE_PROVIDER: 'CognitoIdentityServiceProvider',
    IDENTITY_ID_PREFIX: 'aws.cognito.identity-id',
    USER_DATA: "userData"
};

const AwsCognitoChallengeName = {
    SMS_MFA: 'SMS_MFA',
    SOFTWARE_TOKEN_MFA: 'SOFTWARE_TOKEN_MFA',
    SELECT_MFA_TYPE: 'SELECT_MFA_TYPE',
    MFA_SETUP: 'MFA_SETUP',
    PASSWORD_VERIFIER: 'PASSWORD_VERIFIER',
    CUSTOM_CHALLENGE: 'CUSTOM_CHALLENGE',
    DEVICE_SRP_AUTH: 'DEVICE_SRP_AUTH',
    DEVICE_PASSWORD_VERIFIER: 'DEVICE_PASSWORD_VERIFIER',
    ADMIN_NO_SRP_AUTH: 'ADMIN_NO_SRP_AUTH',
    NEW_PASSWORD_REQUIRED: 'NEW_PASSWORD_REQUIRED',
};

module.exports = {
    AwsCognitoErrorName,
    AwsCognitoMessageAction,
    AwsCognitoInitiateAuthFlow,
    AwsCognitoInitiateAuthFlowParameters,
    AwsCognitoResponseToAuthChallengeName,
    AwsCognitoClientStorageRef,
    AwsCognitoChallengeName,
};
