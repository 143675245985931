import { DataPageTemplate, DataPage, DataPageStylesheet } from './model/index';
import * as Types from './flowTypes';
import { getByPath } from '../src/utils/ramdaEx';
import { getCache } from './dalCache';
import { PAGE_DATA_COMPONENT } from './constants';

const makePageDataCacheKey = (pageId: string) => `${PAGE_DATA_COMPONENT}:${pageId}`;

export const updatePageDataSetCache = (
    { pageId, docs, newResponseBody }: {
        pageId: string;
        docs: Types.UpdateSiteDocumentsInput;
        newResponseBody: Types.UpdateSiteDocumentsResponse
    }
) => {
    const
        key = makePageDataCacheKey(pageId),
        data = getCache().get(key);

    // Prepare data to update in local cache
    if (data) {
        const { body } = data;
        if (docs.page && body.page && newResponseBody.page) {
            body.page = new DataPage({
                ...docs.page,
                etag: newResponseBody.page.etag
            });
        }
        if (docs.template && body.template && newResponseBody.template) {
            body.template = new DataPageTemplate({
                ...docs.template,
                etag: newResponseBody.template.etag
            });
        }
        if (docs.stylesheet && body.stylesheet && newResponseBody.stylesheet) {
            body.stylesheet = new DataPageStylesheet({
                ...docs.stylesheet,
                etag: newResponseBody.stylesheet.etag
            });
        }
    }

    getCache().set(key, data);
};

export const updateRelatedPageDataSetCaches = (
    { docs, newResponseBody }: {
        docs: Types.UpdateSiteDocumentsInput;
        newResponseBody: Types.UpdateSiteDocumentsResponse
    }
) => {
    let predicatePath, predicateValue;
    if (newResponseBody.template) {
        [predicatePath, predicateValue] = [['page', 'templateId'], newResponseBody.template.id];
    } else if (newResponseBody.stylesheet) {
        [predicatePath, predicateValue] = [['template', 'stylesheetId'], newResponseBody.stylesheet.id];
    }

    const pageCacheKeys = getCache().findKeys(
        (value, key) => (
            key.indexOf(`${PAGE_DATA_COMPONENT}:`) === 0 && getByPath(predicatePath, value.body) === predicateValue
        )
    );
    pageCacheKeys.forEach(k => updatePageDataSetCache({ pageId: k.split(':')[1], docs, newResponseBody }));
};
