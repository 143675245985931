import * as R from 'ramda';
import { $Shape } from 'utility-types';
import type { Path } from '../mappers/path';
import { PathsObject } from './assetUtils';

export type PathPart = Array<number | string | PathPart>

export function lensPath(path: Path) {
    return R.compose(...R.map(R.ifElse(
        R.is(Number),
        R.lensIndex,
        R.lensProp
    ))(path));
}

const
    hasNestedArray = R.any(R.is(Array)),
    hasNestedArrayWithNestedArray = R.both(R.is(Array), R.any(hasNestedArray)),
    flippedAppend = R.flip(R.append),
    flippedConcat = R.flip(R.concat);

export function toPathList(paths: Array<PathPart>) {
    const
        result = R.map(
            R.when(R.complement(hasNestedArray), path => [path]),
            flatten(toNestedPathList(paths))
        );

    return R.unnest(result);
}

export const toggleValue = (path: Path) => R.over(R.lensPath(path), R.not);

function flatten(nestedArraysOfPaths: Array<any>) {
    return R.map(
        R.when(hasNestedArrayWithNestedArray, R.pipe(flatten, R.unnest))
    )(nestedArraysOfPaths);
}

export function toNestedPathList(paths: Array<PathPart>) {
    const result = R.map(
        (pathPart: PathPart) => {
            if (R.any(R.is(Array), pathPart)) {
                const pathSplitByArray = R.splitWhen(R.is(Array), pathPart),
                    pathBeforeArray = R.head(pathSplitByArray),
                    pathStartsWithArray = R.last(pathSplitByArray),
                    arrayOfNumbers = R.head(pathStartsWithArray),
                    restAfterNumbers = R.tail(pathStartsWithArray),
                    pathsToUpdate = R.pipe(
                        R.map(flippedAppend(pathBeforeArray)),
                        R.map(flippedConcat(restAfterNumbers))
                    )(arrayOfNumbers),
                    pathWithoutInnerArray = R.map(
                        pathToUpdate => toNestedPathList(pathToUpdate),
                        pathsToUpdate
                    );

                return toNestedPathList(pathWithoutInnerArray);
            } else {
                return pathPart;
            }
        }, paths
    );

    return result;
}

type Predicate = (value: any) => boolean

export const overPathWhen = R.curry((paths: Array<PathPart>, predicate: Predicate) => {
    const pathsToUpdate = toPathList(paths);

    return (updater: Function) => {
        const
            updateInPath = pathToUpdate => {
                const
                    setLens = lensPath(pathToUpdate),
                    getValue = R.path(pathToUpdate);

                return updateTarget => {
                    const currentValue = getValue(updateTarget);

                    if (predicate(currentValue)) {
                        return R.set(setLens, updater(currentValue), updateTarget);
                    } else {
                        return updateTarget;
                    }
                };
            },
            allUpdaters = R.map(updateInPath, pathsToUpdate);

        return R.pipe(...allUpdaters);
    };
});

export const isFunction = R.is(Function);
export const isNotEmpty = R.complement(R.isEmpty);
export const isSymbol = R.is(Symbol);
export const notEquals = R.complement(R.equals);

export const overPath = overPathWhen(R.__, notEquals(undefined));
export const overPathAlways = overPathWhen(R.__, R.T);

export const evolvePath = (path: Path) => overPath([path]);
export const evolvePathAlways = (path: Path) => overPathAlways([path]);
/**
 * TODO: Throw Error when trying to assign integer key to an Object
 * TODO: deep set of existing value
 */
const
    isn = v => Number.isInteger(v),
    isa = Array.isArray,
    iso = v => typeof v === 'object',
    iss = v => isa(v) || iso(v),

    factory = perform => (path: Path, value: any, data: any): any => {
        let
            newData = isn(path[0]) ? (data ? data : []) : { ...data }, // eslint-disable-line
            ref: any = newData;

        for (let i = 0; i < path.length; i++) {
            const
                sp = path[i],
                nsp = path[i + 1],
                isLast = nsp === undefined;

            if (isLast) perform({ ref, sp, value });
            else if (!ref[sp]) ref[sp] = isn(nsp) ? [] : {};
            else {
                if (!iss(ref[sp])) {
                    throw new Error(
                        'Expecting Array | Object at path: "%p"; got: "%v"'
                            .replace('%p', path.slice(0, i + 1).join('.'))
                            .replace('%v', ref[sp])
                    );
                }

                // TODO

                // if (isn(sp) && !isa(ref[sp])) {
                //     throw new Error(
                //         'Expecting Array at path: "%p"; got: "%v"'
                //             .replace('%p', path.slice(0, i + 1).join('.'))
                //             .replace('%v', ref[sp])
                //     );
                // }

                // if (!isn(sp) && (isa(ref[sp]) || !iso(ref[sp]))) {
                //     throw new Error(
                //         'Expecting Object at path: "%p"; got: "%v"'
                //             .replace('%p', path.slice(0, i + 1).join('.'))
                //             .replace('%v', ref[sp])
                //     );
                // }

                // make a shallow copy to 'unfreeze' / preserve prototypes
                // of objects alnog the path
                ref[sp] = isn(nsp) ? [...ref[sp]] : { ...ref[sp] };
            }

            ref = ref[sp];
        }

        return newData;
    };

export const setToPath = factory(({ ref, sp, value }) => {
    ref[sp] = value; // eslint-disable-line no-param-reassign
});

export const getByPath = (
    path: any,
    data: any,
    defaultVal: any = undefined,
): any => {
    const val = R.path(path, data);
    return val === undefined ? defaultVal : val;
};

const _deleteByPath = factory(({ ref, sp }) => {
    if (isa(ref)) ref.splice(sp, 1);
    else delete ref[sp]; // eslint-disable-line no-param-reassign
});
export const deleteByPath = (path: Path, data: AnyValue): AnyValue => {
    const exists = !!getByPath(path, data);
    if (exists) {
        return _deleteByPath(path, null, data);
    }
    return data;
};

export const mapIndexed = R.addIndex(R.map);

type UserFunc = (path: Path, value: any, context: PathsObject) => PathsObject

export const
    traverse = (o: Record<string, any>, func: UserFunc, context: PathsObject, path: Path = []) => {
        const isArray = o instanceof Array;
        let newContext = context;

        Object.keys(o).forEach(key => {
            const
                propVal = o[key],
                propPath = [...path, isArray ? parseInt(key, 10) : key];

            newContext = func(propPath, propVal, newContext);

            if (propVal !== null && propVal instanceof Object) {
                newContext = traverse(propVal, func, newContext, propPath);
            }
        });

        return newContext;
    };

// eslint-disable-next-line max-len
type MakeRecursiveWalkerProps = { map?: (item: Record<string, any>) => Record<string, any>, filter?: (item: Record<string, any>) => boolean }

export const makeRecursiveWalker = (props: MakeRecursiveWalkerProps) => (items: Record<string, any>[]) => {
    return R.pipe(
        props.filter ? R.filter(props.filter) : R.identity,
        R.map(R.evolve({ items: makeRecursiveWalker(props) })),
        props.map ? R.map(props.map) : R.identity
    )(items);
};

export const isEven = (n: number) => n % 2 === 0;
export const isOdd = (n: number) => !isEven(n);

export const propNotEq = R.complement(R.propEq);
export const pathNotEq = R.complement(R.pathEq);
export const noop = () => null;

/**
 * This should work only by reference not by value
 */
export const appendUniqueToList = R.pipe(R.append, R.uniq);

export const merge = <T extends Record<string, any>>(update: $Shape<T>, target: T): T => ({ ...target, ...update });
