import * as Types from './flowTypes';
import { objUriEncode, stringifyQuery } from '../utils/urlBuild';
import { encodeWebspaceUri } from './utils/webspaceUriTransformers';

export const makeUrl = ({
    path,
    query = {},
    options: {
        encodeQuery = false,
        encodeFileName = false
    } = {}
}: Types.MakeDalUrlParams): string => {
    let url = encodeFileName
        ? path.split('/').map(i => (i.indexOf('.') !== -1 ? encodeWebspaceUri(i) : i)).join('/')
        : path;
    if (Object.keys(query).length && Object.keys(query).some(key => query[key])) {
        url += '?' + stringifyQuery(encodeQuery ? objUriEncode(query) : query);
    }

    return url;
};
