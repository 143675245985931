/* @flow */

const
    TRIAL_VALIDITY_DAYS = 14,
    TRIAL_EXISTENCE_DAYS = 28,
    TRIAL_URI_ENTRY_OLD = 'try-old',
    TRIAL_INDEX_FILENAME_OLD = `${TRIAL_URI_ENTRY_OLD}.html`,
    TRIAL_URI_ENTRY = 'try',
    TRIAL_INDEX_FILENAME = `${TRIAL_URI_ENTRY}.html`,
    TRIAL_INTL_PUBLIC_DIR_TAG = ':trialPublicDir',
    TRIAL_INTL_PUBLIC_DIR = 'locale',
    TRIAL_DOMAIN_NAME = 'example.com',
    TRIAL_DOMAIN_SEPARATOR = '@',
    TRIAL_INSTAGRAM_ACCESS_CREDENTIALS_KEY = 'instagramAccessCredentials',
    TRIAL_FACEBOOK_FEED_ACCESS_CREDENTIALS_KEY = 'facebookFeedAccessCredentials',
    TRIAL_FACEBOOK_CHAT_ACCESS_CREDENTIALS_KEY = 'facebookChatAccessCredentials',
    SINGLE_TRIAL_SIGNUP_ENTRY = 'signup',
    MODAL_SINGLE_TRIAL_SIGNUP_ENTRY = `${SINGLE_TRIAL_SIGNUP_ENTRY}-modal`,
    SOCIAL_SIGNUP_FACEBOOK = 'Facebook',
    SOCIAL_SIGNUP_GOOGLE = 'Google',
    FACEBOOK_SIGNUP_USERNAME_PREFIX = 'Facebook_',
    GOOGLE_SIGNUP_USERNAME_PREFIX = 'Google_',
    TRIAL_ENDED_COOKIE = '__trlend',
    TRIAL_IMPORT_STATUS_INPROGRESS = 'inprogress',
    TRIAL_IMPORT_STATUS_DEFAULT = 'default',
    TRIAL_SOCIAL_SSO_FAILURE = '__trlssofail',
    TRIAL_FAILURE_MESSAGES = {
        trialAlreadyPurchased: 'Trial already purchased.',
        userDisabled: 'User is disabled.',
        trialUserIsDisabled: 'Trial user is disabled',
    },
    MERGE_USER_SESSION_IDS_URL = 'trial/track/merge-user-session-ids',
    TRIAL_PRODUCTION_ENVIRONMENT = 'prod',
    TRIAL_PRICE_PLAN = 'PPS_SMALL';

module.exports = {
    TRIAL_VALIDITY_DAYS,
    TRIAL_EXISTENCE_DAYS,
    TRIAL_INTL_PUBLIC_DIR_TAG,
    TRIAL_INTL_PUBLIC_DIR,
    TRIAL_URI_ENTRY,
    TRIAL_INDEX_FILENAME,
    TRIAL_DOMAIN_NAME,
    TRIAL_DOMAIN_SEPARATOR,
    TRIAL_INSTAGRAM_ACCESS_CREDENTIALS_KEY,
    SINGLE_TRIAL_SIGNUP_ENTRY,
    MODAL_SINGLE_TRIAL_SIGNUP_ENTRY,
    TRIAL_URI_ENTRY_OLD,
    TRIAL_INDEX_FILENAME_OLD,
    TRIAL_FACEBOOK_FEED_ACCESS_CREDENTIALS_KEY,
    TRIAL_FACEBOOK_CHAT_ACCESS_CREDENTIALS_KEY,
    SOCIAL_SIGNUP_FACEBOOK,
    SOCIAL_SIGNUP_GOOGLE,
    FACEBOOK_SIGNUP_USERNAME_PREFIX,
    GOOGLE_SIGNUP_USERNAME_PREFIX,
    TRIAL_ENDED_COOKIE,
    TRIAL_SOCIAL_SSO_FAILURE,
    TRIAL_FAILURE_MESSAGES,
    TRIAL_PRICE_PLAN,
    TRIAL_IMPORT_STATUS_INPROGRESS,
    TRIAL_IMPORT_STATUS_DEFAULT,
    MERGE_USER_SESSION_IDS_URL,
    TRIAL_PRODUCTION_ENVIRONMENT
};
