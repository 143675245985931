import { WebspaceEntryContentType } from "../../../../../../server/shared/webspace/WebspaceEntryContentType";

const SUPPORTED_IMAGE_TYPES = ['jpg', 'jpeg', 'png', 'gif', 'webp', 'x-webp'];
const SVG_TYPES = ['svg', 'svg\\+xml'];
const ICON_TYPES = ['vnd\\.microsoft\\.icon', 'x-icon', 'ico'];

export const FcContentTypes = {
        ANY: '^.*$',
        IMAGE: '^image/.*$',
        VIDEO: '^video/.*$|application/mp4',
        SUPPORTED_IMAGE: `^image/(?:${SUPPORTED_IMAGE_TYPES.join('|')})$`,
        SUPPORTED_IMAGE_WITH_SVG: `^image/(?:${[...SUPPORTED_IMAGE_TYPES, ...SVG_TYPES].join('|')})$`,
        SUPPORTED_IMAGE_WITH_ICONS: `^image/(?:${[...SUPPORTED_IMAGE_TYPES, ...ICON_TYPES].join('|')})$`,
        SVG: '^image\\/svg(\\+xml)?$',
        ICON: '^image/(?:vnd\\.microsoft\\.icon|x-icon|ico)$',
        DIRECTORY: `^${WebspaceEntryContentType.DIRECTORY}$`,
        HTML: '^text/html$'
    },
    FcAcceptTypes = {
        VIDEO: '.flv,.3g2,.3gp,.avi,.asf,.divx,.m2ts,.mts,.m4v,.mkv,.mov,.mp4,.mpg,.wmv,video/mp4,video/x-m4v,video/*'
    };
