import DataPageTemplate from './DataPageTemplate';
import DataPageStylesheet from './DataPageStylesheet';

export default class DataPageTemplateSet {
    template: DataPageTemplate;
    stylesheet: DataPageStylesheet;

    constructor(data: Record<string, any>) {
        ['template', 'stylesheet'].forEach(prop => {
            if (!data[prop]) throw new Error('DataPageTemplateSet: incorrect data given');
        });
        this.template = new DataPageTemplate(data.template);
        this.stylesheet = new DataPageStylesheet(data.stylesheet);
    }

    getId(): string {
        return this.template.id;
    }
}
