import Amplify from '@aws-amplify/core';
import type { AppConfig } from "../../components/App/epics/appConfig/types";

export const initAmplify = (appConfig: AppConfig) => {
    const
        awsConfig = appConfig.oneWeb.trial.awsConfig,
        amplifyConfig = {
            "Auth": {
                "identityPoolId": awsConfig.cognito.identityPoolId,
                "userPoolId": awsConfig.cognito.userPoolID,
                "userPoolWebClientId": awsConfig.cognito.appClientId,
            },
            region: awsConfig.region,
        };
    Amplify.configure(amplifyConfig);
};
