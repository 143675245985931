const extend = require('extend');
const hostnetCommonConfig = require('./hostnet.config.js');

const stagingConfig = {
    emailCampaignHost: 'notification.accp.hostnet.nl',
    emailCampaignAuthBearerToken: 'IN1moo7johc5thuol4aef7LohB0niehoon6li7hoh7AN_uphie.quee-G+ie/m0M',
};

const hostnetStagingConfig = extend(true, {}, hostnetCommonConfig, stagingConfig);

module.exports = { ...hostnetStagingConfig };
