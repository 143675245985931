const extend = require('extend');
const hostnetCommonConfig = require('./hostnet.config.js');

const prodConfig = {
    emailCampaignHost: 'notification.hostnet.nl',
    emailCampaignAuthBearerToken: 'eiy9aesh-oh8ii3einaiHohPahsh2ea8Chaeng~ahp-a+ch7vae6Mohfimooshee',
};

const hostnetProdConfig = extend(true, {}, hostnetCommonConfig, prodConfig);
module.exports = { ...hostnetProdConfig };
