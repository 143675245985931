/*
!!! WARNING:
    Do not rename this file as it is used in webpack-config-generator.
    (See around webpack.IgnorePlugin).
!!!
*/

import fetchMock from 'fetch-mock-one.com';
import fetchPonyfill from 'fetch-ponyfill';

export type FetchMockMatcher = string | RegExp | ((arg0: string, arg1: Record<string, any>) => boolean);

export type FetchMockResponseType = any;

type FetchMockResponseFn = (arg0: string, arg1: Record<string, any>) => FetchMockResponseType;

export type FetchMockHeaders = MapT<string>;

export type FetchMockOptions = {
    name?: string,
    headers?: FetchMockHeaders,
    method?: string,
    query?: Record<string, any>,
    overwriteRoutes?: boolean,
    fallbackToNetwork?: boolean,
};

export type FetchMock = {
    matcher: FetchMockMatcher,
    response: FetchMockResponseType,
    name?: string,
    query?: Record<string, any>,
    options?: FetchMockOptions,
};

export interface FetchMockInstance {
    mock(matcher: FetchMockMatcher, response: FetchMockResponseType, options?: FetchMockOptions): FetchMockInstance;
    mock(params: FetchMock): FetchMockInstance;
    get(url: string, arg1: FetchMockResponseType): FetchMockInstance;
    post(url: string, arg1: FetchMockResponseType): FetchMockInstance;
    put(url: string, arg1: FetchMockResponseType): FetchMockInstance;
    matchParams(url: string, resFn: FetchMockResponseFn, options?: FetchMockOptions): FetchMockInstance;
    catch(arg0: FetchMockResponseType): FetchMockInstance;
}

export const mockFetch = (options?: FetchMockOptions): FetchMockInstance => {
    const fetch = fetchMock.sandbox();

    fetch.config = Object.assign({}, fetchMock.config, options, fetchPonyfill()); // eslint-disable-line prefer-object-spread

    /*** Custom methods ***/
    fetch.matchParams = (matcher: string, resFn: FetchMockResponseFn, options?: FetchMockOptions) => {
        const
            templatePath = matcher[0] === '/' ? matcher : `/${matcher}`,
            matcherPath = `express:${templatePath}`;

        return fetch.mock(matcherPath, (url: string, opts: Record<string, any>) => {
            const
                urlParts = url.split('/'),
                params = templatePath
                    .split('/')
                    .reduce(
                        (acc, part, i) => (
                            part.indexOf(':') === 0
                                ? Object.assign(acc, {
                                    [part.substr(1)]: urlParts[i].replace(/\?.*/, ''),
                                })
                                : acc
                        ),
                        {},
                    );

            return resFn(url, { ...opts, params });
        }, options);
    };

    return fetch;
};
