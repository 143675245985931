/* @flow */

/*::
    type Callback<Result> = (err: string | null, result?: Result) => void

    type CallbackFn<Result: any = any> = (...args: (any | Callback<Result>)[]) => void

    type CallbackMethod<Result: any = any> = (...args: (any | Callback<Result>)[]) => void
 */

const promisifyFn = /*::<Result>*/(
    fn/*: CallbackFn<Result> */,
) => (...args/*: any[] */) => new Promise/*::<Result>*/((resolve, reject) => {
    fn(...args, (err, result) => {
        if (err) {
            reject(err);
        } else {
            resolve(result);
        }
    });
});

const promisifyMethod = /*::<Result>*/(
    object/*: {} */,
    method/*: CallbackMethod<Result> */,
    args/*: any[] */ = [],
)/*: Promise<Result> */ => new Promise((resolve, reject) => {
    const cb = (err, result) => {
        if (err) {
            reject(err);
        } else {
            resolve(result);
        }
    };

    method.call(object, ...[...args, cb]);
});

const promisifyMethodMultiArgs = /*::<Result>*/(
    object/*: {} */,
    method/*: CallbackMethod<Result> */,
    args/*: any[] */ = [],
)/*: Promise<Result> */ => new Promise((resolve, reject) => {
    const cb = (err, ...response) => {
        if (err) {
            reject(err);
        } else {
            resolve(response);
        }
    };

    method.call(object, ...[...args, cb]);
});

// Returns a wrapper function that will promisify a given callback function.
// It will preserve 'this'.
function promisifyIfNoCallback(fn /*: function */) {
    return function (...argumentsArray /*: any[] */) {
        // If the last argument is a function, assume its the callback.
        let callback = argumentsArray[argumentsArray.length - 1];

        // If the callback is given, don't promisify, just pass straight in.
        if (typeof callback === 'function') {
            return fn.apply(this, argumentsArray);
        }

        // Otherwise, create a new set of arguments, and wrap
        // it in a promise.
        let args = [...argumentsArray];

        // $FlowFixMe
        return new Promise((resolve) => {
            // Add the callback function.
            args.push((err, ...response) => {
                if (err) {
                    return resolve([err]);
                }

                resolve([null, ...response]);
            })

            // Call the function with our special adaptor callback added.
            fn.apply(this, args)
        })
    }
}

module.exports = {
    promisifyFn,
    promisifyMethod,
    promisifyMethodMultiArgs,
    promisifyIfNoCallback
};
