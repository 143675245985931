let networkLog: string[] = [];
const MAXLogLen = 40;

export function log(str: string) {
    const d = new Date();
    networkLog.push(`${d.toString()} ${str}`);
    if (networkLog.length > MAXLogLen) {
        networkLog.shift();
    }
}

export const getNetworkLog = () => [...networkLog];
