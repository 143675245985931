/* @flow */

const path = require('path');

const TrialStoragePath = Object.freeze({
    PART: {
        USERS: 'users',
        ONEWEBMEDIA: 'onewebmedia',
    },

    users: (...parts/*: string[] */)/*: string */ => path.join(TrialStoragePath.PART.USERS, ...parts),

    userRoot: (userId/*: string */, ...parts/*: string[] */)/*: string */ =>
        TrialStoragePath.users(userId, ...parts),

    userDefault: (userId/*: string */, ...parts/*: string[] */)/*: string */ =>
        TrialStoragePath.userRoot(userId, TrialStoragePath.PART.ONEWEBMEDIA, ...parts),
});

module.exports = {
    TrialStoragePath,
};
