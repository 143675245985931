export default class {
    data: Record<string, any>;

    constructor(data: Record<string, any>) {
        this.data = { ...data };
    }

    all(): Record<string, any> {
        return this.data;
    }

    get(key: string, def: any = null): any {
        return this.data.hasOwnProperty(key) ? this.data[key] : def;
    }

    has(key: string): boolean {
        return this.data.hasOwnProperty(key);
    }

    isEmpty(): boolean {
        return Object.keys(this.data).length === 0;
    }
}
