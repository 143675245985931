const np = require('path');
const fs = require('fs');
const extend = require('extend');
const { trim } = require('./string.js');

const path = {
    // common paths
    ROOT: __dirname + '/../../',

    to(path) {
        return np.normalize(this.ROOT + this.ltrim(path));
    },

    toScripts(path = '') {
        return this.to('scripts/' + this.ltrim(path));
    },

    toHttpPub(path = '') {
        return this.to('http-pub/' + this.ltrim(path));
    },

    toRelativeWbtgenStatic(...parts) {
        return np.join('wbtgen/static', ...parts);
    },

    toHttpPubProd(path = '') {
        return this.to('http-pub-production/' + this.ltrim(path));
    },

    toNodeModules(path = '') {
        return this.to('node_modules/' + this.ltrim(path));
    },

    toWbtgen(path = '') {
        return this.to('wbtgen/' + this.ltrim(path));
    },

    toDashboard(path = '') {
        return this.to('src/dashboard/' + this.ltrim(path));
    },

    toWebpack(...parts) {
        return np.join(this.toWbtgen('webpack'), ...parts);
    },

    toWebpackConfig(...parts) {
        return this.toWebpack('config', ...parts);
    },

    toWebpackLoaders(...parts) {
        return this.toWebpack('loaders', ...parts);
    },

    toServer(path = '') {
        return this.to('server/' + this.ltrim(path));
    },

    toTrial(path = '') {
        return this.to('trial/' + this.ltrim(path));
    },

    toTargetDir(env, ...parts) {
        const dir = env.targetDir ? this.to(env.targetDir) : this.toHttpPub();
        return np.join(dir, ...parts);
    },

    ltrim(path) {
        if (path[0] === '/') {
            return path.substring(1);
        }
        return path;
    },

    rtrim(path) {
        if (path.substr(-1) === '/') {
            return path.substring(0, path.length - 1);
        }
        return path;
    },

    trim(path) {
        return this.ltrim(this.rtrim(path));
    },

    build(parts) {
        return parts.map(p => this.trim(p)).filter(p => !!p).join('/');
    },

    join(...parts) {
        const flatten = require('ramda/src/flatten');
        return np.join(...flatten(parts));
    },

    resolve() {
        const args = Object.keys(arguments).map((k, i) => (i === 0 ? arguments[k] : this.ltrim(arguments[k])));
        return np.resolve(...args);
    },

    resolveFromProjectRoot() {
        const args = Object.keys(arguments).map((k, i) => (i === 0 ? arguments[k] : this.ltrim(arguments[k])));
        return np.resolve(__dirname, '../..', ...args);
    },

    exists(path) {
        return fs.existsSync(path);
    },

    basename(path, ext = null) {
        return np.basename(path, ext ? '.' + trim(ext, '.') : '');
    },

    arrayBasename(path) {
        return path.slice(0, -1);
    },

    extname(path, dotted = false) {
        let ext = np.extname(path);
        if (!dotted && ext[0] === '.') ext = ext.substring(1);
        return ext;
    },

    filename(name, ext) {
        return `${name}.${ext.replace('.', '')}`;
    },

    extend(customPath) {
        return extend({}, this, customPath);
    }
};

module.exports = path;
