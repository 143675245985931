import { diff } from 'objectdiff';
import ImageResource from '../redux/modules/children/fileChooser/ImageResource';
import DataPageRef from '../../dal/model/DataPageRef';
import DataPageSet from '../../dal/model/DataPageSet';
import DataPage from '../../dal/model/DataPage';
import DataPageTemplate from '../../dal/model/DataPageTemplate';
import DataPageStylesheet from '../../dal/model/DataPageStylesheet';
import DataSite from '../../dal/model/DataSite';
import DataLinkPage from '../../dal/model/DataLinkPage';
import DataPageLayout from '../../dal/model/DataPageLayout';
import DataRepositorySite from '../../dal/model/DataRepositorySite';
import Resource from '../redux/modules/children/fileChooser/Resource';
import DataPageTemplateRef from '../../dal/model/DataPageTemplateRef';
import { ActionType } from '../epics/makeCondition';
import { init as initDAL } from '../../dal/init';

/* some global debug staff */
const $R: any = {
    namespace: {
        DataPageRef,
        DataPageSet,
        DataPage,
        DataPageTemplate,
        DataPageStylesheet,
        DataSite,
        DataLinkPage,
        Resource,
        DataPageLayout,
        DataRepositorySite,
        ImageResource,
        DataPageTemplateRef,
        ActionType
    }
};

$R.initDal = initDAL;

if (window) {
    window.$R = $R;
    $R.diff = diff;
}

export default $R;
