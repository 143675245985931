import memoizee from 'memoizee';

const defaultConfig = {
    maxAge: 30000
};

export function memoMaxOne(fun: Function) {
    return memoizee(fun, { max: 1 });
}

export function memoMax(fun: Function, max: number) {
    return memoizee(fun, { max });
}

export const makeMemoMax = (max: number) => (fun: Function) => memoizee(fun, { max });

export function memoMaxDynamicNumOfArgs(fun: Function, max: number) {
    return memoizee(fun, { max, length: false });
}

export function memoMaxFixedNumOfArgs(fun: Function, max: number, length: number) {
    return memoizee(fun, { max, length });
}

export function memoMaxOneCheckOneArg(fun: Function) {
    return memoizee(fun, { max: 1, length: 1 });
}

export function memoUnlimited(fun: Function) {
    return memoizee(fun);
}

export default (fun: Function, config: {} = defaultConfig) => {
    return memoizee(fun, config);
};
