/* WSB DEMO INIT */

/*
!!! WARNING:
    Do not rename this file as it is used in webpack-config-generator.
    (See around webpack.IgnorePlugin).
!!!
*/

import { setTrialRuntime, TrialRuntime } from '../../trial/services/trialRuntime';
import { setIsWsbDemo } from '../src/debug/isWsbDemo';
import { mockDemoFetch } from './mockDemoFetch';
import { initDemoAWS } from "../src/services/aws/initDemoAWS";

export const initDemo = () => {
    setTrialRuntime(TrialRuntime.WBTGEN);

    setIsWsbDemo();
    mockDemoFetch();
    initDemoAWS();
};
