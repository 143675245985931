/* @flow */

const TrialRuntimeProcessEnv = 'TRIAL_RUNTIME';

const TrialRuntime = {
    LAMBDA: 'lambda',
    SERVER: 'server',
    WBTGEN: 'wbtgen',
    CMD: 'cli',
    TEST: 'test',
};

/*::
    export type TrialRuntimeT = $Values<typeof TrialRuntime>;
 */

class TrialRuntimeService {
    /*:: #envRuntime: TrialRuntimeT */

    constructor() {
        // $FlowFixMe
        this./*::#*/envRuntime = process.env[TrialRuntimeProcessEnv];
        if (!this./*::#*/envRuntime || Object.values(TrialRuntime).indexOf(this./*::#*/envRuntime) === -1) {
            throw new Error(`Unknown or unset process.env.${TrialRuntimeProcessEnv} value: ${this./*::#*/envRuntime}`);
        }
    }

    get value() {
        return this./*::#*/envRuntime;
    }

    isLambda()/*: boolean */ {
        return this./*::#*/envRuntime === TrialRuntime.LAMBDA;
    }

    isServer()/*: boolean */ {
        return this./*::#*/envRuntime === TrialRuntime.SERVER;
    }

    isWbtgen()/*: boolean */ {
        return this./*::#*/envRuntime === TrialRuntime.WBTGEN;
    }

    isCommandLine()/*: boolean */ {
        return this./*::#*/envRuntime === TrialRuntime.CMD;
    }

    isTestEnv()/*: boolean */ {
        return this./*::#*/envRuntime === TrialRuntime.TEST;
    }
}

let trialRuntimeSingletone;

const setTrialRuntime = (runtime/*: TrialRuntimeT */) => {
    process.env[TrialRuntimeProcessEnv] = runtime;
};

const getTrialRuntime = ()/*: TrialRuntimeService */ => {
    if (!trialRuntimeSingletone) {
        trialRuntimeSingletone = new TrialRuntimeService();
    }
    return trialRuntimeSingletone;
};

module.exports = {
    TrialRuntimeProcessEnv,
    TrialRuntime,
    setTrialRuntime,
    getTrialRuntime,
};
