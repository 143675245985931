/* @flow */

const extend = require('extend');
const { getTrialRuntime } = require("../trialRuntime");
const AwsS3Module = require('../../../server/shared/awsServices/AwsS3');
const { TrialStoragePath } = require('./TrialStoragePath');

/*::
    import type { AwsS3Options, AWSRequest } from '../../../server/shared/awsServices/types';
    import type { AwsTrialConfig } from '../types'
 */

function BaseTrialStorage(config/*: AwsTrialConfig */) {
    /*=== Constructor ===*/

    const defaultS3Options/*: AwsS3Options */ = makeDefaultS3Options();

    /*=== Public ===*/

    BaseTrialStorage.prototype.getUserFileObject = (
        userId/*: string */,
        fileName/*: string */,
        s3Options/*:? Object */ = {},
    )/*: AWSRequest */ => this._pm // eslint-disable-line no-underscore-dangle
        .s3(s3Options)
        .getObject({
            Key: this._pm.getUserFileKey(userId, fileName), // eslint-disable-line no-underscore-dangle
        });

    BaseTrialStorage.prototype.listUserFiles = (
        userPath/*: string | [string, string] */,
        s3Options/*?: AwsS3Options */,
    )/*: AWSRequest */ => {
        const [userId, path = ''] = Array.isArray(userPath) ? userPath : [userPath];
        // eslint-disable-next-line no-underscore-dangle
        return this._pm
            .s3(s3Options)
            .listObjectsV2({
                Prefix: TrialStoragePath.userRoot(userId, '/', path),
            });
    };

    /*=== Protected ===*/

    const pm = {};

    pm.s3 = (extraOptions/*: AwsS3Options */) => {
        const s3Options = extend(true, {}, defaultS3Options, extraOptions);
        return new AwsS3Module.AwsS3(s3Options);
    };

    pm.getUserFileKey = (userId/*: string */, filename/*: string */)/*: string */ =>
        TrialStoragePath.userDefault(userId, filename);

    // eslint-disable-next-line no-underscore-dangle
    this._pm = pm;

    /*=== Private ===*/

    function makeDefaultS3Options() {
        const options/*: AwsS3Options */ = {
            region: config.region,
            params: {
                Bucket: config.s3BucketName,
            },
        };

        if (config.localstackEndpoints && config.localstackEndpoints.S3 && config.localstackEndpoints.S3 !== "") {
            options.endpoint = config.localstackEndpoints.S3;
            options.s3ForcePathStyle = true;
        }

        if (getTrialRuntime().isServer()) {
            if (!config.server) {
                throw new Error('Missing trial server config at: trial.awsConfig.server');
            }
            options.accessKeyId = config.server.accessKeyId;
            options.secretAccessKey = config.server.secretAccessKey;
        }

        return options;
    }
}

module.exports = { BaseTrialStorage };
