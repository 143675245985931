import { isObj } from '../isType';

// TODO: extract to utils without ramda ?

const setToPath = (path, val, obj) => {
    let o = obj || {};

    for (let i = 0; i < path.length - 1; i++) {
        const k = path[i];
        if (!o[k]) {
            o[k] = {};
        }
        o = o[k];
    }
    o[path[path.length - 1]] = val;

    return obj;
};

const castValues = val => {
    // cast
    if (val === 'null') return null;
    if (val === 'true') return true;
    if (val === 'false') return false;

    // traverse
    if (isObj(val)) {
        return Object
            .keys(val)
            .reduce(
                (acc, key) => Object.assign(acc, { [key]: castValues(val[key]) }),
                {},
            );
    }
    return val;
};

export const getWindowLocationQuery = (): Record<string, any> => {
    const search = window.location.search;
    if (!search) return {};

    return search
        .substr(1)
        .split('&')
        .reduce(
            (acc, pair) => {
                const
                    split = pair.split('='),
                    key = split[0],
                    val = split[1] ? castValues(split[1]) : true;

                if (key.indexOf('[')) {
                    const path = key
                        .split('[')
                        .map(k => k.replace(']', ''));

                    return setToPath(path, val, acc);
                }

                return Object.assign(acc, { [key]: val });
            },
            {},
        );
};
