import CALL_API from '../../../../middleware/api/CALL_API';
import {
    FC_RESOURCE_METADATA_REQUEST,
    FC_RESOURCE_METADATA_SUCCESS,
    FC_RESOURCE_METADATA_FAILURE
} from '../actionTypes';

import type { ApiAction } from '../../../../middleware/api/flowTypes';

export const GET_RESOURCE_METADATA_FOR_SAVE_API_TAG = 'GET_RESOURCE_METADATA_FOR_SAVE_API_TAG';

export default function (resourcePath: string, tag?: string): ApiAction {
    return {
        [CALL_API]: {
            types: [
                FC_RESOURCE_METADATA_REQUEST,
                FC_RESOURCE_METADATA_SUCCESS,
                FC_RESOURCE_METADATA_FAILURE
            ],
            endpoint: 'getResourceMetadata',
            endpointParams: resourcePath,
            tag
        }
    };
}
