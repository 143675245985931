/* @flow */

const
    isStr = (val /*: any */) /*: boolean */ /* :: %checks */ => typeof val === 'string',
    isObj = (val /*: any */)/*: boolean */  /* :: %checks */ => typeof val === 'object' && val !== null && !Array.isArray(val),
    isArr = (val /*: any */)/*: boolean */  /* :: %checks */ => Array.isArray(val),
    isFn = (val /*: any */)/*: boolean */  /* :: %checks */ => typeof val === 'function',
    isBool = (val /*: any */)/*: boolean */  /* :: %checks */ => val === true || val === false;

module.exports = {
    isStr,
    isObj,
    isArr,
    isFn,
    isBool,
};
