/* @flow */

const FacebookFeedUrl = {
    ROOT: '/facebookfeed',
    EXCHANGE_ACCESS_TOKEN: '/exchangeAccessToken',
    AUTHORISE_CALLBACK: '/authorize',
    GET_ACCESS_TOKEN: '/auth/getAccessToken',
    GET_USER_MEDIA: '/getUserMedia',
    MARK_USER_FOR_DELETE: '/markUserForDelete',
    DATA_DELETE: '/auth/datadelete',
    GET_COMPONENTS_USAGE: '/getComponentsUsage',
    TOGGLE_PAGE_VISIBILITY: '/togglePageVisibility',
};

module.exports = {
    FacebookFeedUrl
};
