/* @flow */

// TODO: move to AwsCognitoUserNativeAttribute

const TrialUserAttributes = {
    SUB: "sub",
    EMAIL_VERIFIED: "email_verified",
    EMAIL: "email",
    LOCALE: "locale",
    IDENTITIES: "identities",
    MAIL_CAMPAIGN_AGREED: "mail_campaign_agreed",
};

module.exports = {
    TrialUserAttributes,
};
