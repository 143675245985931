/* @flow */

const { AuthClass } = require('@aws-amplify/auth');

/*::
    import type { ICognitoStorage } from './types'

    type AwsAuthOptions = {|
        identityPoolId: string,
        userPoolId: string,
        userPoolWebClientId: string,
        region: string,
        storage?: ICognitoStorage,
    |}
 */

/**
 * See AuthClass API: https://aws-amplify.github.io/amplify-js/api/classes/authclass.html.
 */
class AwsAuth extends AuthClass {
    // TODO: fix storage problem with AuthClass initialization
    static forNode(options/*: AwsAuthOptions */) {
        if (!global.fetch) {
            global.fetch = require('node-fetch');
        }

        return new AwsAuth(options);
    }

    constructor(options/*: AwsAuthOptions */) {
        super(options);
    }
}

module.exports = { AwsAuth };
