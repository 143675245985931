import isTestEnv from './isTestEnv';

let isDemo = false;

export const setIsWsbDemo = () => {
    if (isDemo && !isTestEnv()) {
        throw new Error('isDemo can only be set once.');
    }
    isDemo = true;
};

export const resetIsWsbDemo = () => {
    if (!isTestEnv()) {
        throw new Error('Can only resetIsWsbDemo() in test.');
    }
    isDemo = false;
};

export const isWsbDemo = (): boolean => isDemo;
