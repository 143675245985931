import * as R from 'ramda';
import { COOKIE_RESULT } from "../redux/middleware/cookie/actionTypes";

type Selector = (obj: any, type: string) => AnyValue
type Predicate = (obj: any) => boolean

type ActionTypeConfig = {
    actionType?: string,
    isOptional?: boolean,
    reset?: boolean,
    receiveOnly?: boolean,
    selector?: Selector,
    predicate?: Predicate,
    actionTypesToResetAfterRun?: Array<string>,
    whenReason?: string,
    whenApiTag?: string,
    anyOf?: Array<string>
}

class ActionType {
    actionType!: string;
    isOptional!: boolean;
    reset!: boolean;
    receiveOnly!: boolean;
    selector!: Selector;
    predicate!: Predicate;
    actionTypesToResetAfterRun: null | undefined | Array<string>;
    whenReason!: string;
    whenApiTag!: string;
    anyOf: null | undefined | Array<string>;

    constructor(config: ActionTypeConfig) {
        if (!config.actionType && (!config.anyOf || !config.anyOf.length)) {
            throw new Error('ActionType.actionType can not be empty');
        }
        Object.assign(this, config);
    }

    toString() {
        return this.anyOf ? this.anyOf.join('&') : this.actionType;
    }

    _clone() {
        return new ActionType(((this as any) as ActionTypeConfig));
    }
}

function withSelector(actionType: string | ActionType, selector: Selector) {
    if (!selector) {
        throw new Error(`withSelector for "${actionType.toString()}" called without selector`);
    }

    if (actionType instanceof ActionType) {
        const newActionType = actionType._clone();
        newActionType.selector = selector;
        return newActionType;
    }

    return new ActionType({ actionType, selector });
}

function reset(actionType: string, selector?: Selector) {
    return new ActionType({ actionType, reset: true, selector });
}

function optionalReset(actionType: string, selector?: Selector) {
    return new ActionType({ actionType, reset: true, isOptional: true, selector });
}

function optional(actionType: string | ActionType, selector?: Selector) {
    if (actionType instanceof ActionType) {
        const newActionType = actionType._clone();
        newActionType.isOptional = true;
        if (selector) {
            newActionType.selector = selector;
        }

        return newActionType;
    }

    return new ActionType({ actionType, isOptional: true, selector });
}

function receiveOnly(actionType: string | ActionType, selector?: Selector) {
    if (actionType instanceof ActionType) {
        const newActionType = actionType._clone();
        newActionType.receiveOnly = true;
        if (selector) {
            newActionType.selector = selector;
        }

        return newActionType;
    }

    return new ActionType({ actionType, receiveOnly: true, selector });
}

function optionalReceiveOnly(actionType: string, selector?: Selector) {
    return new ActionType({ actionType, receiveOnly: true, isOptional: true, selector });
}

function optionalResetReceiveOnly(actionType: string, selector?: Selector) {
    return new ActionType({ actionType, reset: true, receiveOnly: true, isOptional: true, selector });
}

function when(actionType: string, predicate: Predicate) {
    return new ActionType({ actionType, predicate });
}

function whenWithSelector(actionType: string, selector: Selector, predicate: Predicate) {
    return new ActionType({ actionType, predicate, selector });
}

function whenReason(actionType: string, whenReason: string): ActionType {
    return new ActionType({ actionType, whenReason });
}

function whenApiTag(actionType: string, apiTag: string): ActionType {
    return new ActionType({ actionType, whenApiTag: apiTag });
}

function optionalResetWhenApiTag(actionType: string, apiTag: string): ActionType {
    return new ActionType({ actionType, reset: true, isOptional: true, whenApiTag: apiTag });
}

function receiveOnlyWhenReason(actionType: string, whenReason: string): ActionType {
    return new ActionType({ actionType, whenReason, receiveOnly: true });
}

function whenCookieKey(whenCallerId: string, whenCookieKey: string) {
    return when(COOKIE_RESULT, ({ payload: { callerId, cookieKey } }) => (
        whenCallerId === callerId && whenCookieKey === cookieKey
    ));
}

function resetWhen(actionType: string, predicate?: Predicate) {
    return new ActionType({ actionType, predicate, reset: true });
}

function anyOf(...actionTypes: Array<string | ActionType>) {
    const
        anyOf: string[] = [],
        state = {},
        selectors = {},
        initialValue = Symbol('initialValue');

    actionTypes.forEach(actionType => {
        const type = actionType.toString();

        anyOf.push(type);
        state[type] = initialValue;
        // @ts-ignore
        selectors[type] = actionType.selector || R.identity;
    });

    let currentValue = false;
    return new ActionType({
        anyOf,
        selector: (payload, type) => {
            const value = selectors[type](payload, type);
            if (state[type] === initialValue || state[type] !== value) {
                state[type] = value;
                currentValue = !currentValue;
            }

            return currentValue;
        }
    });
}

export {
    reset,
    resetWhen,
    optionalReset,
    optional,
    receiveOnly,
    optionalReceiveOnly,
    optionalResetReceiveOnly,
    withSelector,
    when,
    whenWithSelector,
    whenReason,
    whenCookieKey,
    ActionType,
    whenApiTag,
    receiveOnlyWhenReason,
    optionalResetWhenApiTag,
    anyOf
};
