export default class DataPageStylesheet {
    type!: "web.data.styles.Stylesheet";
    id!: string;
    name!: string;
    styles!: Record<string, any>[];
    time!: number;
    etag!: string;
    rev!: number;
    _stylesCache: Record<string, null | undefined | Record<string, any>> = {};

    constructor(data: Record<string, any>) {
        Object.assign(this, data);
    }

    getGlobalStyleById(id: string) {
        if (this._stylesCache[id]) return this._stylesCache[id];

        for (let i = 0; i < this.styles.length; i++) {
            const style = this.styles[i];
            if (style.id === id) {
                // this._stylesCache[id] = style; // TODO: WBTGEN-2190
                return style;
            }
        }
        throw new Error('Cant find global style by id: ' + id);
    }

    getFirstGlobalStyleByType(type: string): null | Record<string, any> {
        for (let i = 0; i < this.styles.length; i++) {
            if (this.styles[i].type === type) {
                return this.styles[i];
            }
        }
        return null;
    }

    getGlobalStyleByRef(ref: string, type: string): null | undefined | Record<string, any> {
        // const key = type + '.' + ref;
        // if (this._stylesCache[key] !== undefined) return this._stylesCache[key]; // TODO: WBTGEN-2190

        let result: Record<string, any> | null = null;
        for (let i = 0; i < this.styles.length; i++) {
            const style = this.styles[i];
            if (style.type === type && style.ref === ref) {
                result = style;
                break;
            }
        }
        // this._stylesCache[key] = result; // TODO: WBTGEN-2190
        return result;
    }

    getGlobalStyleBySymbol(symbol: string, type: string): null | undefined | Object {
        // const key = symbol + ':' + type;
        // if (this._stylesCache[key] !== undefined) return this._stylesCache[key]; // TODO: WBTGEN-2190
        // console.log('symbol', symbol)
        const
            aliases = DataPageStylesheet.breakSymbolToAliases(symbol),
            needles: Record<string, any> = [];
        aliases.forEach(a => {
            this.styles.forEach(style => {
                if ((a === style.ref || symbol === style.name) && type === style.type) needles.push(style);
            });
        });
        // console.log('needles', needles.map(n => n.ref + ':' + n.id))
        const result = needles.shift() || null;
        // this._stylesCache[key] = result; // TODO: WBTGEN-2190
        return result;
    }

    /**
     * Breaks symbol down to possible aliases.
     *
     * @param inSymbol - style ref OR name
     *
     * @example
     * -> symbol: "heading.header.3" // The original name passed in
     *
     * -> becomes
     * [
     *     "heading.header.3",
     *     "heading.header.2",
     *     "heading.header.1",
     *     "heading.header",
     *     "heading"
     * ]
     */
    static breakSymbolToAliases(inSymbol: string): Array<string> {
        const
            symbol = inSymbol.replace(/\[|\]/g, ''),
            aliases: string[] = [],
            parts = symbol.split('.');

        // Add the alias itself first
        aliases.push(symbol);

        while (parts.length > 1) {
            const
                last = parts.pop() as string,
                prefix = parts.join('.');

            // if the last option is a number do a count down for alias options to add
            let lastNr = parseInt(last, 10);
            if (!isNaN(lastNr) && lastNr > 1) {
                for (lastNr -= 1; lastNr > 0; lastNr--) {
                    aliases.push(prefix + '.' + lastNr);
                }
            }

            // mind pure prefix as well
            aliases.push(prefix);
        }

        return aliases;
    }
}
