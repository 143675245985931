import { ApiActionTypes } from "../../../middleware/api/flowTypes";
import Resource from "./Resource";

export const
    FC_RESOURCES_SELECTED = 'FC_RESOURCES_SELECTED',
    FC_RESOURCES_REQUEST = 'FC_RESOURCES_REQUEST',
    FC_RESOURCE_UPLOADING = 'FC_RESOURCE_UPLOADING',
    FC_RESOURCE_UPLOAD_CANCELED = 'FC_RESOURCE_UPLOAD_CANCELED',
    FC_RESOURCE_UPLOADING_FAILED = 'FC_RESOURCE_UPLOADING_FAILED',
    FC_RESOURCE_UPLOADING_SUCCESS = 'FC_RESOURCE_UPLOADING_SUCCESS',
    FC_RESOURCES_FETCH_SUCCESS = 'FC_RESOURCES_FETCH_SUCCESS',
    FC_RESOURCES_FETCH_FAILURE = 'FC_RESOURCES_FETCH_FAILURE',
    FC_RESOURCES_FETCH_ACTIONS: ApiActionTypes = [
        FC_RESOURCES_REQUEST,
        FC_RESOURCES_FETCH_SUCCESS,
        FC_RESOURCES_FETCH_FAILURE
    ],
    FC_SORT = 'FC_SORT',
    FC_TOGGLE_RESOURCE_SELECTION = 'FC_TOGGLE_RESOURCE_SELECTION',
    FC_TOGGLE_ALL_RESOURCES_SELECTION = 'FC_TOGGLE_ALL_RESOURCES_SELECTION',
    FC_NAVIGATE_SUB_DIRECTORY = 'FC_NAVIGATE_SUB_DIRECTORY',
    FC_NAVIGATE_DIRECTORY = 'FC_NAVIGATE_DIRECTORY',
    FC_INIT = 'FC_INIT',
    FC_INITIALIZED = 'FC_INITIALIZED',
    FC_RESET = 'FC_RESET',
    FC_RESET_SELECTION = 'FC_RESET_SELECTION',
    FC_SET_MULTI_SELECT = 'FC_SET_MULTI_SELECT',
    FC_TOGGLE_VIEW_MODE = 'FC_TOGGLE_VIEW_MODE',
    FC_URL_UPLOAD = 'FC_URL_UPLOAD',
    FC_URL_UPLOAD_REQUEST = 'FC_URL_UPLOAD_REQUEST',
    FC_URL_UPLOAD_SUCCESS = 'FC_URL_UPLOAD_SUCCESS',
    FC_URL_UPLOAD_FAILURE = 'FC_URL_UPLOAD_FAILURE',
    FC_URL_UPLOAD_ACTION_TYPES: ApiActionTypes = [
        FC_URL_UPLOAD_REQUEST,
        FC_URL_UPLOAD_SUCCESS,
        FC_URL_UPLOAD_FAILURE
    ],
    FC_FILES_UPLOAD_REQUEST_URL = 'FC_FILES_UPLOAD_REQUEST_URL',
    FC_FILES_UPLOAD_REQUEST_URL_SUCCESS = 'FC_FILES_UPLOAD_REQUEST_URL_SUCCESS',
    FC_FILES_UPLOAD_REQUEST_URL_FAILURE = 'FC_FILES_UPLOAD_REQUEST_URL_FAILURE',
    FC_FILES_UPLOAD_REQUEST_URL_ACTION_TYPES: ApiActionTypes = [
        FC_FILES_UPLOAD_REQUEST_URL,
        FC_FILES_UPLOAD_REQUEST_URL_SUCCESS,
        FC_FILES_UPLOAD_REQUEST_URL_FAILURE
    ],
    FC_ADD_GHOST_RESOURCE = 'FC_ADD_GHOST_RESOURCE',
    FC_REMOVE_GHOST_RESOURCE = 'FC_REMOVE_GHOST_RESOURCE',
    FC_REMOVE_GHOST_RESOURCE_BY_NAME = 'FC_REMOVE_GHOST_RESOURCE_BY_NAME',
    FC_FETCH_GHOST_RESOURCE = 'FC_FETCH_GHOST_RESOURCE',
    FC_COMPUTER_UPLOAD = 'FC_COMPUTER_UPLOAD',
    FC_COMPUTER_UPLOAD_REQUEST = 'FC_COMPUTER_UPLOAD_REQUEST',
    FC_COMPUTER_UPLOAD_SUCCESS = 'FC_COMPUTER_UPLOAD_SUCCESS',
    FC_COMPUTER_UPLOAD_FAILURE = 'FC_COMPUTER_UPLOAD_FAILURE',
    FC_COMPUTER_UPLOAD_STARTED = 'FC_COMPUTER_UPLOAD_STARTED',
    FC_COMPUTER_UPLOAD_FINISHED = 'FC_COMPUTER_UPLOAD_FINISHED',
    FC_UPLOAD_OVERWRITE = 'FC_UPLOAD_OVERWRITE',
    FC_CONFLICT_DIALOG_CLOSE = 'FC_CONFLICT_DIALOG_CLOSE',
    FC_CONFLICT_RENAME_DIALOG_CLOSE = 'FC_CONFLICT_RENAME_DIALOG_CLOSE',
    FC_UPLOAD_RENAME = 'FC_UPLOAD_RENAME',
    FC_UPLOAD_RETRY = 'FC_UPLOAD_RETRY',
    FC_UPLOAD_CANCEL = 'FC_UPLOAD_CANCEL',
    FC_CREATE_WEBSPACE_FOLDER_REQUEST = 'FC_CREATE_WEBSPACE_FOLDER_REQUEST',
    FC_CREATE_WEBSPACE_FOLDER_SUCCESS = 'FC_CREATE_WEBSPACE_FOLDER_SUCCESS',
    FC_CREATE_WEBSPACE_FOLDER_FAILURE = 'FC_CREATE_WEBSPACE_FOLDER_FAILURE',
    FC_RESOURCE_METADATA_REQUEST = 'FC_RESOURCE_METADATA_REQUEST',
    FC_RESOURCE_METADATA_SUCCESS = 'FC_RESOURCE_METADATA_SUCCESS',
    FC_RESOURCE_METADATA_FAILURE = 'FC_RESOURCE_METADATA_FAILURE',
    FC_SAVE = 'FC_SAVE',
    FREE_ONE_COM_SAVE = 'FREE_ONE_COM_SAVE',
    FC_SET_CONTENT_TYPES = 'FC_SET_CONTENT_TYPES',
    FC_CREATE_WEB_SPACE_FOLDER = 'FC_CREATE_WEB_SPACE_FOLDER',
    FC_DELETE_REQUEST = 'FC_DELETE_REQUEST',
    FC_DELETE_CONFIRM = 'FC_DELETE_CONFIRM',
    FC_DELETE_CANCEL = 'FC_DELETE_CANCEL',
    FC_DELETE_RETRY = 'FC_DELETE_RETRY',
    FC_DELETE_SUCCESS = 'FC_DELETE_SUCCESS',
    FC_DELETE_FAILURE = 'FC_DELETE_FAILURE',
    FC_DELETE_ACTION_TYPES: ApiActionTypes = [
        FC_DELETE_REQUEST,
        FC_DELETE_SUCCESS,
        FC_DELETE_FAILURE
    ] as ApiActionTypes,
    FC_DELETE_RESOURCE = 'FC_DELETE_RESOURCE',
    _FC_SET_RESOURCES = '_FC_SET_RESOURCES',
    _FC_SET_FILE_LIST_UPLOAD_PROGRESS = '_FC_SET_FILE_LIST_UPLOAD_PROGRESS',
    _FC_SET_SELECTION = '_FC_SET_SELECTION',
    _FC_SET_DEFAULT_STATE = '_FC_SET_DEFAULT_STATE';

export const
    FC_MY_IMAGES_EDIT_ACTION = 'FC_MY_IMAGES_EDIT_ACTION',
    fcMyImagesEditAction = (payload: Resource) => ({
        type: FC_MY_IMAGES_EDIT_ACTION,
        payload,
    });

export const FC_MY_IMAGES_EDIT_SAVE_ACTION = 'FC_MY_IMAGES_EDIT_SAVE_ACTION';
