// TODO: fix flow
// TODO: tests

import qs from 'querystring';
import urlKit from 'url';
import pathParse from 'path-parse';
import Bag from "./Bag";

const Hash = function (rawHash: string = '') { // eslint-disable-line flowtype/require-return-type
    /* Constructor */
    let data = {};
    const hash = rawHash.replace('#', '');
    if (hash) {
        data = qs.parse(hash);
    }

    const self = new Bag(data);

    /* Public */
    // @ts-ignore
    self.getRawHash = function (): string {
        return rawHash;
    };

    return self;
};

const Url = function (urlStr) {
    // @ts-ignore
    const url = new urlKit.Url().parse(urlStr, true /* parseQueryString */);
    url.query = new Bag(url.query);
    // @ts-ignore
    url.hash = new Hash(url.hash || '');

    url.getRelative = () => {
        return url.hostname + url.path;
    };

    url.getPath = () => {
        return pathParse(url.path);
    };

    return url;
};

export default Url;
