/* @flow */

module.exports = {
    label: 'Hostnet bv',
    startYear: '1999',
    logoTargetUrl: 'https://www.hostnet.nl',
    helpContactUrl: 'https://helpdesk.hostnet.nl/hc/nl-nl',
    cookiePolicyUrl: 'https://www.hostnet.nl/over-hostnet/privacy-en-cookieverklaring',
    privacyPolicyUrl: 'https://www.hostnet.nl/over-hostnet/privacy-en-cookieverklaring',
    termsUrl: 'https://www.hostnet.nl/over-hostnet/algemene-voorwaarden',
    setExternalMailCampaignNotification: true,
    emailCampaignPath: '/api/v1/website-builder/trial',
    skipNotificationsWhenCreatingControlPanelUsers: true,
    sourceEmailPartner: "Hostnet",
};
