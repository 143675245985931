/* @flow */

module.exports = {
    label: 'one.com',
    startYear: '2002',
    logoTargetUrl: 'https://www.one.com/en/',
    helpContactUrl: 'https://www.one.com/contact',
    cookiePolicyUrl: 'msg: demo.login.cookiePolicy.link {https://www.one.com/en/info/cookie-policy}',
    privacyPolicyUrl: 'msg: demo.login.termsAndCondition.privacyPolicy {https://www.one.com/en/info/privacy-policy}',
    termsUrl: 'msg: demo.login.termsAndCondition.termsOfUse.link {https://www.one.com/en/terms-and-conditions-eur}',
    skipNotificationsWhenCreatingControlPanelUsers: false,
    sourceEmailPartner: "one.com",
};
