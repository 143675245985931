const
    DATA_PAGE_REF_TYPE = 'web.data.links.LinkPage',
    DATA_LINK_PAGE_TYPE = 'web.data.links.LinkExternal',
    DATA_LINK_SECTION_TYPE = 'web.data.links.LinkSection';

export {
    DATA_PAGE_REF_TYPE,
    DATA_LINK_PAGE_TYPE,
    DATA_LINK_SECTION_TYPE,
};
