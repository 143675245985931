import DataPage from "./DataPage";
import DataPageTemplate from './DataPageTemplate';
import DataPageStylesheet from './DataPageStylesheet';

import { OldComponentTypes } from "../pageMapAdapter/componentTypesMap";

export default class DataPageSet {
    page: DataPage;
    template: DataPageTemplate;
    stylesheet: DataPageStylesheet;

    _componentGlobalStyles!: Record<string, any>[];

    constructor(data: Record<string, any>) {
        ['page', 'template', 'stylesheet'].forEach(prop => {
            if (!data[prop]) throw new Error('DataPageSet: incorrect data given');
        });
        this.page = new DataPage(data.page);
        this.template = new DataPageTemplate(data.template);
        this.stylesheet = new DataPageStylesheet(data.stylesheet);
    }

    getComponentsGlobalStyles(): Record<string, any>[] {
        if (this._componentGlobalStyles) return this._componentGlobalStyles;

        const styles: Record<string, any>[] = [];
        this.page.items.forEach(component => {
            this._getComponentStyles(component).forEach(componentStyle => {
                const { globalId } = componentStyle;
                if (globalId) {
                    styles.push(componentStyle);
                }
            });
        });

        this._componentGlobalStyles = styles;
        return styles;
    }

    getTemplateId(): string {
        return this.page.templateId;
    }

    _getComponentStyles(component: Record<string, any>): Record<string, any>[] { // eslint-disable-line class-methods-use-this
        const
            styles: any = [],
            type = component.type;

        if (type === OldComponentTypes.BLOCK) {
            styles.push(component.style);
        } else if (type === OldComponentTypes.BUTTON || type === OldComponentTypes.CONTACTFORM) {
            styles.push(component.styleButton);
        } else if (type === OldComponentTypes.MENU) {
            styles.push(component.menuStyle);
            // TODO WBTGEN-3042: accordionStyles, cascadeStyles ?
        } else if (type === OldComponentTypes.TABLE) {
            if (Array.isArray(component.cells)) {
                component.cells.forEach(c => {
                    if (c[2] && c[2].style) styles.push(c[2].style);
                });
            }

            if (Array.isArray(component.cellsData)) {
                component.cellsData.forEach(c => {
                    if (c.style) styles.push(c.style);
                });
            }
        }

        return styles;
    }
}
