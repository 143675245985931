export const
    Navigation = 'msg: shortcut.navigation {Navigation}',
    NavigationKind = 'Navigation',
    Gallery = 'msg: component.gallery.label {Gallery}',
    Slider = 'msg: shortcut.slider {Image slider}',
    Sections = 'msg: shortcut.sections {Sections}',
    Containers = 'msg: shortcut.container {Containers}',
    SectionsKind = 'SectionsKind',
    ContainersKind = 'ContainersKind',
    Youtube = 'youtube',
    Contact = 'msg: common.contact {Contact}',
    Phone = "msg: component.contactForm.fields.phone {Phone}",
    Email = "msg: common.email {Email}",
    Address = 'msg: common.address {Address}',
    Social = 'msg: shortcut.social {Social}',
    OpeningHours = 'msg: common.openingHours {Opening hours}',
    More = 'msg: shortcut.more {More}',
    MoreKind = 'More';
