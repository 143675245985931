/* @flow */

const { AwsAuth } = require('../../server/shared/awsServices/AwsAuth');
const { getTrialAwsConfig } = require('./getTrialConfig');
const { AwsAmplifyCognitoUser } = require("../../server/shared/awsServices/cognito/AwsAmplifyCognitoUser.js");
const { promisifyMethod } = require("../../server/shared/utils/promisify/promisify.js");
const { isServerTestEnv } = require('../../server/lib/utils/isServerTestEnv.js');

/*::
    import type { AwsCognitoUserT } from '../../server/shared/awsServices/cognito/types'
    import type { AwsTrialConfig, TrialUserCredentials, TrialUserInfo } from './types'
 */

class TrialAuth {
    /*:: #auth: AwsAuth */

    constructor(awsConfig/*: AwsTrialConfig */) {
        this./*::#*/auth = AwsAuth.forNode({
            identityPoolId: awsConfig.cognito.identityPoolId,
            userPoolId: awsConfig.cognito.userPoolID,
            userPoolWebClientId: awsConfig.cognito.appClientId,
            region: awsConfig.region,
        });
    }

    getEssentialCredentials()/*: Promise<Object> */ {
        return this./*::#*/auth
            .currentCredentials()
            .then(credentials => this./*::#*/auth.essentialCredentials(credentials));
    }

    /**
     * !!! USE WITH CAUTION !!!
     *
     * When refresh=true it will hard refresh user's tokens / credentials which,
     * which is unnecessary in most cases.
     */
    getAccessTokenAndEssentialCredentials(
        refresh/*: boolean */,
    )/*: Promise<{| accessToken: string, credentials: TrialUserCredentials |}> */ {
        return this./*::#*/auth
            .currentUserPoolUser()
            .then(user => {
                if (refresh) {
                    return promisifyMethod(user, user.getSession)
                        .then(session => session.getRefreshToken())
                        .then(refreshToken => promisifyMethod(user, user.refreshSession, [refreshToken]))
                        .then(session => session.getAccessToken().getJwtToken());
                }

                return AwsAmplifyCognitoUser(user).storage.getAccessToken();
            })
            .then(
                accessToken => {
                    const credentialsToken = refresh
                        ? this./*::#*/auth
                            .currentUserCredentials()
                            .then(credentials => this./*::#*/auth.essentialCredentials(credentials))
                        : this.getEssentialCredentials();

                    return credentialsToken
                        .then(credentials => ({ accessToken, credentials }));
                },
            );
    }

    getUserInfo()/*: Promise<TrialUserInfo> */ {
        return this./*::#*/auth.currentUserInfo();
    }

    getCredentialsAndUserInfo()/*: Promise<{| credentials: TrialUserCredentials, userInfo: TrialUserInfo |}> */ {
        return this
            .getEssentialCredentials()
            .then(
                credentials => this.getUserInfo().then(userInfo => {
                    if (!userInfo) {
                        throw new Error('Missing user info');
                    }

                    return ({
                        credentials,
                        userInfo,
                    });
                }),
            );
    }

    signIn(username/*: string */, pw/*: string */)/*: Promise<AwsCognitoUserT> */ {
        return this./*::#*/auth.signIn(username, pw);
    }
}

const Module = {};

Module.TrialAuth = TrialAuth;

let trialAuthSingleton;

Module.getTrialAuth = () => {
    if (!trialAuthSingleton || isServerTestEnv()) {
        trialAuthSingleton = new Module.TrialAuth(getTrialAwsConfig());
    }
    return trialAuthSingleton;
};

module.exports = Module;
