import sha1 from 'sha1';
import fsz from 'filesize';
import df from 'dateformat';
import ellipsis from 'text-ellipsis';
import { getDAL } from "../../../../../dal/init";
import pathUtil from '../../../../../utils/path';
import Url from "../../../../../utils/Url";
import urlBuild from "../../../../../utils/urlBuild";
import { FC_ONEWEBMEDIA_PATH, FC_KNOWN_SYSYTEM_PATHS } from './constants';
import { testFcImageContentType, testFcVideoContentType, testFcDirectoryContentType } from "./utils/testFcContentType";
import { TRIAL_STORAGE_PROTOCOL } from "../../../../../../trial/services/storage/constants";
import AppConfig from "../../../../utils/AppConfig";
import { getAppConfig } from "../../../../components/App/epics/appConfig/appConfig";
import { DAL_API_PREFIX, REPOSITORY_PREFIX, WEBSPACE_PREFIX } from '../../../../../dal/constants';

const IMAGE_ICON_SIZE = 30;

export const getShortName = (name: string, length: number) => {
        return ellipsis(name, length);
    },
    systemResources = [
        ...FC_KNOWN_SYSYTEM_PATHS,
        ...AppConfig(getAppConfig()).getOptional('oneWeb.fileChooser.systemResourcePaths', [])
    ];
export default class Resource {
    static getBaseName(path: string, ext: null | undefined | string = null) {
        return pathUtil.basename(path.trim(), ext);
    }

    static isWebspaceUrl(url: string): boolean {
        return url.indexOf(WEBSPACE_PREFIX) === 0
            || url.indexOf(REPOSITORY_PREFIX) === 0
            || url.indexOf(DAL_API_PREFIX) !== -1;
    }

    static fromWebspaceUrl(url: string): Resource {
        if (url.indexOf(TRIAL_STORAGE_PROTOCOL) === 0) {
            const
                basePath = FC_ONEWEBMEDIA_PATH,
                href = pathUtil.basename(url);

            return new Resource(basePath, { href, isTrial: true });
        }

        if (!Resource.isWebspaceUrl(url)) throw new Error(`Failed to build Resource from non-webspace url: ${url}`);

        const parsedUrl = Url(url);

        if (!parsedUrl.path) return new Resource(FC_ONEWEBMEDIA_PATH, { href: '' });

        const
            parts = parsedUrl.path
                .substring(parsedUrl.path.indexOf('webspace/'))
                .split('/'),
            basePath = parts.length > 2 ? pathUtil.join(parts.slice(1, -1)) : '/',
            href = pathUtil.join(parts.slice(-1));

        if (url.indexOf(REPOSITORY_PREFIX) === 0) return new Resource(FC_ONEWEBMEDIA_PATH, { href });

        return new Resource(basePath, { href });
    }

    id: string;
    basePath: string;
    href!: string;
    contentType!: string;
    createdDate!: number;
    size!: number;
    etag!: string;
    thumbnailUrl!: string;
    isGhost = false;
    isLoading = false;
    isTrial: boolean = false;

    constructor(basePath: string, data: Record<string, any>) {
        this.basePath = basePath;
        Object.keys(data).forEach(name => {
            // $FlowFixMe: missing indexing property
            this[name] = data[name];
        });
        this.id = sha1(basePath + ':' + this.href);
    }

    getId() {
        return this.id;
    }

    getPath() {
        return !this.isDirectory() ? this._buildWebspacePath() : null;
    }

    getWebspacePath() {
        return pathUtil.build([this.basePath, this.getName()]);
    }

    getRelativePath() {
        let
            prefix = '/',
            basePath = pathUtil.trim(this.basePath);
        if (basePath) prefix += `${basePath}/`;

        return prefix + pathUtil.trim(this.href);
    }

    getWebSpaceProtocolRelativePath() {
        if (this.isTrial) {
            return TRIAL_STORAGE_PROTOCOL + this.getRelativePath();
        }
        return WEBSPACE_PREFIX + this.getRelativePath();
    }

    getImagePath(
        width: number | null,
        height: number | null,
        {
            imageTimestamp,
            // $FlowFixMe: TODO
            crop = 'center',
            withoutEnlargement,
        }: {
            imageTimestamp?: null | undefined | number,
            crop?: string | false,
            withoutEnlargement?: boolean,
        } = {},
    ): string {
        const query: Record<string, any> = {
            resize: `${width === null ? '' : width},${height === null ? '' : height}`,
            etag: this.etag,
        };

        if (crop) {
            query.crop = crop;
        }
        if (imageTimestamp) query.t = imageTimestamp;
        if (withoutEnlargement) query.withoutEnlargement = null;

        return this._buildWebspacePath(query);
    }

    getImageIconPath() {
        return this.getImagePath(IMAGE_ICON_SIZE, IMAGE_ICON_SIZE);
    }

    getFullImagePath() {
        return this._buildWebspacePath();
    }

    getFullUrl() {
        return urlBuild(this.getRelativePath(), { scheme: 'http', host: getDAL().getDomain() });
    }

    getName() {
        return Resource.getBaseName(this.href);
    }

    getHref() {
        return this.href;
    }

    getShortName(length: number) {
        return getShortName(this.getName(), length);
    }

    getBaseName() {
        return Resource.getBaseName(this.href, this.getRenderContentType());
    }

    getShortBaseName(length: number) {
        return getShortName(this.getBaseName(), length);
    }

    getContentType() {
        return this.contentType;
    }

    getRenderContentType() {
        return this.isDirectory() ? null : pathUtil.extname(this.href);
    }

    getSize() {
        return this.size ? fsz(this.size, { round: 1, symbols: { B: 'bytes' } }) : null;
    }

    getRawSize() {
        return this.size;
    }

    getCreated() {
        return df(this.createdDate, 'dd/mm/yyyy');
    }

    getRawCreated() {
        return this.createdDate;
    }

    isDirectory() {
        return testFcDirectoryContentType(this.contentType);
    }

    isImage() {
        return testFcImageContentType(this.contentType);
    }

    isVideo() {
        return testFcVideoContentType(this.contentType);
    }

    isSystemResource() {
        return systemResources.some(sysResPath =>
            pathUtil.trim(this.getRelativePath()) === pathUtil.trim(sysResPath));
    }

    _getFullPathForFreeOneComThumbnail() {
        if (this.thumbnailUrl) {
            return this.thumbnailUrl;
        }
        return "";
    }

    /* Private */
    _getFullPath() {
        let path = pathUtil.build([this.basePath, this.href]);
        if (this.isTrial) {
            path = `${TRIAL_STORAGE_PROTOCOL}${path}`;
        }
        return path;
    }

    _buildWebspacePath(query?: Record<string, any>) {
        return getDAL().makeWebSpaceUrl({ path: this._getFullPath(), query });
    }
}
