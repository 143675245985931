/* @flow */

const AwsCognitoUserNativeAttribute = {
    SUB: "sub",
    EMAIL_VERIFIED: "email_verified",
    EMAIL: "email",
    LOCALE: "locale",
    USERNAME: 'username',
    PHONE_NUMBER: 'phone_number',
    NAME: 'name',
    GIVEN_NAME: 'given_name',
    FAMILY_NAME: 'family_name',
    PREFERRED_USERNAME: 'preferred_username',
    STATUS: 'status',
};

module.exports = {
    AwsCognitoUserNativeAttribute,
};
