const
    FETCH_FAILED_ERROR = 'Failed to fetch',
    JSON_PARSE_FAILED_MSG = /Unexpected token .* in JSON.*/,
    EMPTY_JSON_ERROR = 'Unexpected end of JSON input',
    NOT_FOUND_ERROR = 'NotFound',
    INVALID_OR_MISSING_COOKIE_ERROR = 'INVALIDORMISSINGCOOKIE',
    FETCH_ABORT_ERROR = 'AbortError',
    WSB_STREAM_ERROR = new RegExp('WSBSTREAMERROR:*');

export {
    FETCH_FAILED_ERROR,
    JSON_PARSE_FAILED_MSG,
    EMPTY_JSON_ERROR,
    NOT_FOUND_ERROR,
    INVALID_OR_MISSING_COOKIE_ERROR,
    FETCH_ABORT_ERROR,
    WSB_STREAM_ERROR,
};
