import type { DalResponseSet, DalResponseSetGenericBody } from "./flowTypes";
import {
    GET_RESOURCE_METADATA_FOR_SAVE_API_TAG
} from "../src/redux/modules/children/fileChooser/actionCreators/getResourceMetadata";
import { testFcContentType } from "../src/redux/modules/children/fileChooser/utils/testFcContentType";
import { FcContentTypes } from "../src/redux/modules/children/fileChooser/FcContentTypes";

export default (
    { response, body }: DalResponseSet<DalResponseSetGenericBody>,
    apiTag?: string
): boolean => {
    // server api is a full and deep mess :(
    // handle case when metadata for .ico files is returned as a 200 error and should be passed as success ...
    // and only when saving from file chooser (but not when uploading) ...
    if (
        apiTag === GET_RESOURCE_METADATA_FOR_SAVE_API_TAG
        && response.ok
        && response.status === 200
        && body.error
        && body.contentType
        && testFcContentType(body.contentType, FcContentTypes.ICON)
    ) {
        return true;
    }

    return response.ok
        && response.status >= 200 && response.status < 300
        && !(!Array.isArray(body) && body.error);
};
