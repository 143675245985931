import npath from 'path';
import { makeUri } from '@libj/make-uri';
import { Ctor } from '../../../server/shared/utils/ctor/Ctor';
import { BaseTrialStorage } from "../../../trial/services/storage/BaseTrialStorage";
import { getTrialAuth } from "../../../trial/services/trialAuth";
import { getTrialAwsConfig } from "../../../trial/services/getTrialConfig";
import type { AwsTrialConfig } from '../../../trial/services/types';
import { TrialStoragePath } from "../../../trial/services/storage/TrialStoragePath";
import { AwsStorageErrorCode } from "../../../server/shared/awsServices/constants/AwsStorageErrorCode";
import type { WebspaceEntriesResBody } from "../../dal/flowTypes";
import { WebspaceEntryResourceType } from "../../../server/shared/webspace/WebspaceEntryResourceType";
import { WebspaceEntryContentType } from "../../../server/shared/webspace/WebspaceEntryContentType";
import { reencodeWebspaceUri } from "../../dal/utils/webspaceUriTransformers";
import { getTrialLambda } from "../../../trial/services/trialLambda";
import fetch from '../../src/services/fetch';
import { DemoFileUploadLimitReached } from "./DemoFileUploadLimitReached";

/*::
    import { TrialAuth } from '../../../trial/services/trialAuth';
    import { AwsS3 } from '../../../server/shared/awsServices/AwsS3'
    import type { AwsCredentials } from '../../../server/shared/awsServices/types'
*/

function WbtgenTrialStorage(this: any, config: AwsTrialConfig) {
    /*=== Constructor ===*/

    Ctor.super(BaseTrialStorage, [this, config]);

    const
        self = this,
        auth/*: TrialAuth */ = getTrialAuth(),
        lambda = getTrialLambda();

    /*=== Public ===*/

    WbtgenTrialStorage.prototype.createUserMediaFolder = (userId: string): Promise<Record<string, any>> => {
        const userFolderPath = TrialStoragePath.userDefault(userId, "/");
        return getS3().then(awsS3 => awsS3
            .headObject({ Key: userFolderPath }).promise()
            .catch(headObjErr => {
                if (headObjErr.code === AwsStorageErrorCode.NOT_FOUND) {
                    return uploadViaPreSignedUrl(userFolderPath, new window.File([''], ''));
                }
                throw headObjErr;
            }));
    };

    // TODO: refactor to use  BaseTrialStorge.listUserFiles only (needs introduction of abstract getS3()) ? (WBTGEN-12576)
    WbtgenTrialStorage.prototype.listCurrentUserResources = (): Promise<WebspaceEntriesResBody> =>
        auth
            .getCredentialsAndUserInfo()
            .then(({ credentials, userInfo }) => this.listUserFiles(
                [userInfo.id, TrialStoragePath.PART.ONEWEBMEDIA],
                { credentials }
            ).promise())
            .then(data => ({
                entries: data.Contents.reduce((acc, s3Object) => {
                    const resourcePath = s3Object.Key;

                    // skip directories for now
                    if (resourcePath[resourcePath.length - 1] === '/') {
                        return acc;
                    }

                    const
                        filename = reencodeWebspaceUri(npath.basename(resourcePath)),
                        ext = npath.extname(filename).split('.')[1],
                        modifiedDate = s3Object.LastModified.getTime();

                    return acc.concat({
                        contentType: npath.join(WebspaceEntryContentType.IMAGE, ext),
                        createdDate: modifiedDate,
                        etag: s3Object.ETag,
                        href: filename,
                        modifiedDate,
                        resourceType: WebspaceEntryResourceType.FILE,
                        size: s3Object.Size,
                        isTrial: true,
                    });
                }, []),
            }));

    WbtgenTrialStorage.prototype.makeUserResourceUrl = (
        userIdentityId: string,
        resourcePath: string,
        query?: Record<string, any>,
    ): string => {
        const
            awsImproConfig = config.impro,
            filename = reencodeWebspaceUri(npath.basename(resourcePath)),
            path = awsImproConfig.path.replace('{id}', userIdentityId).replace('{image}', filename);

        return makeUri(path, { query: { $data: query, $options: { encode: false } } });
    };

    WbtgenTrialStorage.prototype.makeCurrentUserResourceUrl = (
        resourcePath: string,
        query?: Record<string, any>,
    ): Promise<string> => auth
        .getUserInfo()
        .then(userInfo => this.makeUserResourceUrl(userInfo.id, resourcePath, query));

    // TODO: test
    WbtgenTrialStorage.prototype.uploadCurrentUserFile = (filename: string, file: File): Promise<Record<string, any>> =>
        auth
            .getUserInfo()
            .then(userInfo => {
                const fileName = TrialStoragePath.userDefault(userInfo.id, decodeURIComponent(filename));
                return uploadViaPreSignedUrl(fileName, file);
            });

    /*=== Private ===*/

    // TODO: proper annotation of AwsS3
    function getS3(): Promise<Record<string, any>> { // eslint-disable-line no-unused-vars
        return auth
            .getEssentialCredentials()
            .then(credentials => self._pm.s3({ credentials }));
    }

    function uploadViaPreSignedUrl(filePath: string, file: File): Promise<Record<string, any>> {
        return auth
            .getUserInfo()
            .then(userInfo => {
                const generatePreSignedURLParams: Record<string, any> = {
                    functionName: `wsb-${getTrialAwsConfig().stage}-s3PresignedUrl`,
                    functionInvokeArgs: {
                        fileName: filePath,
                        userName: userInfo.username,
                        totalSizeToBeUploaded: file.size,
                        contentType: file.type,
                    },
                };
                return auth
                    .getEssentialCredentials()
                    .then(credentials => {
                        generatePreSignedURLParams.lambdaOptions = { credentials };
                        return lambda
                            .invokeFunction(generatePreSignedURLParams)
                            .then(preSignedUrlResponse => {
                                const presignedRequestData = JSON.parse(preSignedUrlResponse.Payload);
                                if (!presignedRequestData.url) {
                                    throw new DemoFileUploadLimitReached('Unable to upload data');
                                }
                                const formData = new FormData();
                                Object.keys(presignedRequestData.fields).forEach(keyName => {
                                    formData.append(keyName, presignedRequestData.fields[keyName]);
                                });

                                formData.append('file', file);

                                const fetchOptions = {
                                    method: "POST",
                                    body: formData
                                };
                                return fetch(presignedRequestData.url, fetchOptions)
                                    .then(() => {
                                        return {};
                                    });
                            });
                    });
            });
    }
}
Ctor.extends(WbtgenTrialStorage, BaseTrialStorage);

export const getWbtgenTrialStorage = () => {
    return new WbtgenTrialStorage(getTrialAwsConfig());
};
