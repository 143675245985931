export class DemoFileUploadLimitReached extends Error {
    constructor(message?: string) {
        super(message || '');

        this.name = 'DemoFileUploadLimitReached';

        if (typeof Error.captureStackTrace === 'function') {
            Error.captureStackTrace(this, this.constructor);
        } else {
            this.stack = (new Error(message)).stack;
        }
    }
}
