/* @flow */

// TODO: move to server/shared

// NOTE: Custom attributes once created in the user pool can not be modified.

/*::

    type CustomAttrConfig = {|
        attrName: string,
        appClientReadable?: boolean,
        appClientWritable?: boolean,
        attributeMutable?: boolean,
        attributeDataType?: string,
        stringAttributeMinLength?: number,
        stringAttributeMaxLength?: number,
        numberAttributeMinValue?: number,
        numberAttributeMaxValue?: number,
    |}
*/

const makeAttrName = attr => `custom:${attr}`;

const generateAttrConfig = (attr /*: CustomAttrConfig */) => ({
    attrName: attr.attrName,
    appClientReadable: attr.appClientReadable || false,
    appClientWritable: attr.appClientWritable || false,
    attributeMutable: attr.attributeMutable || true,
    attributeDataType: attr.attributeDataType || 'String', // One of String | Number | DateTime | Boolean
    stringAttributeMinLength: attr.stringAttributeMinLength || 1,
    stringAttributeMaxLength: attr.stringAttributeMaxLength || 256,
    numberAttributeMinValue: attr.numberAttributeMinValue || 1,
    numberAttributeMaxValue: attr.numberAttributeMaxValue || 1000,
});

const TrialUserCustomAttributesMap /*: { [key: string]: CustomAttrConfig } */ = {
    DATA_IMPORTED: {
        attrName: "data_imported",
    },
    CREATED_AT: {
        attrName: "created_at",
        appClientReadable: true,
    },
    COGNITO_ID: {
        attrName: "cognito_id",
    },
    VERIFICATION_CODE: {
        attrName: "verification_code",
    },
    HASHED_SUB_ID: {
        attrName: "hashed_sub_id",
        appClientReadable: true,
    },
    RECAPTCHA_CODE: {
        attrName: "recaptcha_code",
        appClientWritable: true,
        stringAttributeMaxLength: 1024,
    },
    IMPORT_DOMAIN_NAME: {
        attrName: "import_domain_name",
    },
    SIGNUP_REQUEST_HOST_NAME: {
        attrName: "signup_host_name",
        appClientWritable: true,
    },
    IMPORT_ATTEMPTS_COUNTER: {
        attrName: "import_counter",
    },
    IMPORT_STATUS: {
        attrName: "import_status",
    },
    IMPORT_FAILURE_NOTIFIED: {
        attrName: "import_fail_notified",
    },
    SIGNUP_IP_ADDRESS: {
        attrName: "signup_ip_address",
        appClientWritable: true,
    },
    ABT: {
        attrName: "abt",
        appClientReadable: true,
        appClientWritable: true,
    },
    IS_ONE_COM_CP_USER: {
        attrName: "is_one_com_cp_user",
        appClientReadable: true,
    },
    MAIL_CAMPAIGN_AGREED: {
        attrName: "mail_campaign_agreed",
        appClientReadable: true,
        appClientWritable: true,
    },
    PARTNER_NOTIFIED: {
        attrName: "partner_notified",
    },
};

const TrialUserCustomAttributes/*: { [id: $Keys<typeof TrialUserCustomAttributesMap>]: string } */ = Object
    .keys(TrialUserCustomAttributesMap)
    .reduce(
        (acc, k) => Object.assign(acc, { [k]: makeAttrName(TrialUserCustomAttributesMap[k].attrName) }),
        {},
    );

const TrialUserCustomAttributesSetupConfig/*: Object */ = Object
    .keys(TrialUserCustomAttributesMap)
    .reduce(
        (acc, k) => Object.assign(acc, { [k]: generateAttrConfig(TrialUserCustomAttributesMap[k]) }),
        {},
    );

module.exports = {
    TrialUserCustomAttributes,
    TrialUserCustomAttributesSetupConfig,
};
