import { initCache } from './dalCache';
import { createDalWithWebSpaceUrlFactory } from './createDal';

let dal;

export const createDal = (domain: string, makeWebSpaceUrlFactory?: Function) => {
    return createDalWithWebSpaceUrlFactory(domain, makeWebSpaceUrlFactory);
};

export const init = (domain: string, makeWebSpaceUrlFactory?: Function) => {
    initCache();
    dal = createDal(domain, makeWebSpaceUrlFactory);
};

export const getDAL = (): Record<string, any> => {
    if (!dal) {
        throw new Error('dal is not initialized');
    }
    return dal;
};

export const isDALInitialized = (): boolean => !!dal;
